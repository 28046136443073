export function simpleSingular(word) {
    if (word.slice(-1) === 's') {
        return word.slice(0, -1);
    }

    return word;
}

export function durationToText(duration) {


    return duration.units !== 'inst' ? `${duration.value} ${duration.units}` : 'Instant';
}

export function rangeToText(range) {
    if (range.value > 0) {
        return `${range.value} ${range.units}`;
    }

    return range.units;
}

export function activationToText(activation) {
    if (activation.cost > 0) {
        return `${activation.cost} ${activation.type}`;
    }

    return activation.type;
}