import React from 'react';
import {getColumns} from '../helpers/form';
import {NPC_CONDITIONS} from './npcConstants';

class ConditionTypeList extends React.Component {

    renderColumn = (types) => {
        return types.map(type => <div className="form-group col-4" key={`condition-${type.key}`}>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" name="skills" value={type.key} id={`condition${type.key}Check`} checked={this.props.hasType(type.key)} onChange={this.props.changeType} />
                <label className="form-check-label" htmlFor={`condition${type.key}Check`}>
                    {type.name}
                </label>
            </div>
        </div>);
    };

    render() {
        const columns = getColumns(NPC_CONDITIONS, 2);

        return <div className="row">
            {columns.map((column, index) => {
                return <div className="col-6" key={`condition-col-${index}`}>
                    {this.renderColumn(column)}
                </div>;
            })}
        </div>;
    }
}

export default ConditionTypeList;