import Model from './model';
import {NPC_CONDITIONS, NPC_DAMAGETYPES, NPC_LANGUAGES, NPC_SKILLS} from '../components/npc/npcConstants';
import {initialNpc} from '../store/reducer';
import {simpleSingular} from '../components/helpers/text';

const spellsData = require('../dataset/spells.json');

class Npc extends Model {

    constructor(name, ac, hp, attackRoll, damageRoll, contentUrl, content) {
        super();
        this.id = this.createRandomString(16);
        this.name = name;
        this.ac = ac;
        this.hp = hp;
        this.attackRoll = attackRoll;
        this.damageRoll = damageRoll;
        this.url = contentUrl;
        this.content = content;
    }

    static checkBackwardsCompatibility(npc) {
        const {speed, speeds, senses, languages, skills, resistances, damage_resistances, immunities, damage_immunities, vulnerabilities, damage_vulnerabilities, conditionImmunities, condition_immunities} = npc;

        if (typeof languages === 'string') {
            npc.languages = Npc.findLanguages(languages);
        }

        if (typeof senses === 'string') {
            npc.senses = Npc.findSenses(senses);
        }

        if (typeof speeds === 'undefined') {
            npc.speeds = Npc.findSpeeds(speed);
        }

        if (typeof vulnerabilities === 'undefined') {
            npc.vulnerabilities = Npc.findDamageTypes(damage_vulnerabilities);
        }

        if (typeof resistances === 'undefined') {
            npc.resistances = Npc.findDamageTypes(damage_resistances);
        }

        if (typeof immunities === 'undefined') {
            npc.immunities = Npc.findDamageTypes(damage_immunities);
        }

        if (typeof conditionImmunities === 'undefined') {
            npc.conditionImmunities = Npc.findDamageTypes(condition_immunities);
        }

        if (typeof skills === 'undefined') {
            npc.skills = Npc.findSkills(npc);
        }

        npc.actions = Npc.findActionDamageRolls(npc.actions);
        npc.actions = Npc.findActionData(npc.actions);
        npc.special_abilities = Npc.findActionDamageRolls(npc.special_abilities);
        npc.special_abilities = Npc.findActionData(npc.special_abilities);
        npc.legendary_actions = Npc.findActionDamageRolls(npc.legendary_actions);
        npc.legendary_actions = Npc.findActionData(npc.legendary_actions);

        npc.spells = Npc.findSpellDamageRolls(npc.spells);
        npc.spells = Npc.findSpellData(npc.spells);

        return Object.assign({...initialNpc}, npc);
    }

    static findSpellcastingAbility(abilities) {
        if (abilities && abilities.length > 0) {
            for (const ability of abilities) {
                const expr = /spellcasting ability is (Strength|Dexterity|Constitution|Intelligence|Wisdom|Charisma)/
                const match = ability.desc.match(expr);

                if (match) {
                    return match[1].toLowerCase();
                }
            }
        }

        return '';
    }

    static findSpeeds(speed) {
        const speeds = {};
        let pattern = /^(\d{1,}) ft\./;
        let match = speed.match(pattern);

        speeds['walk'] = match && match[1];

        for (let name of ['fly', 'burrow', 'climb', 'swim']) {
            let pattern = `${name} (\\d{1,}) ft\\.`;
            let match = speed.match(pattern);
            if (match) {
                speeds[name] = match[1];
            }
        }

        const {walk, fly, burrow, climb, swim} = speeds;
        return {
            walking: walk || 0,
            flying: fly || 0,
            burrowing: burrow || 0,
            climbing: climb || 0,
            swimming: swim || 0,
        }
    }

    static findSenses(sense) {
        const senses = {};

        for (let name of ['darkvision', 'blindsight', 'truesight', 'tremorsense']) {
            let pattern = `${name} (\\d{1,}) ft\\.`;
            let match = sense.match(pattern);
            if (match) {
                senses[name] = match[1];
            }
        }

        return senses;
    }

    static findSkills(npc) {
        const skills = [];

        NPC_SKILLS.forEach(skill => {
            if (npc[skill.itemKey]) {
                skills.push(skill.key);
            }
        })

        return skills;
    }

    static findActionDamageRolls(actions) {
        if (!actions) {
            return actions;
        }

        return actions.map(action => {
            if (!Array.isArray(action.damage_parts) && action.damage_dice && action.damage_bonus) {
                action.damage_parts = [[`${action.damage_dice} + ${action.damage_bonus}`, 'slashing']];
            }

            return action;
        });
    }

    static findSpellDamageRolls(actions) {
        if (!actions) {
            return [];
        }

        return actions.map(action => {
            if ((!action.damage || !Array.isArray(action.damage.parts)) && action.damage_dice && action.damage_bonus) {
                action.damage.parts = [[`${action.damage_dice} + ${action.damage_bonus}`, 'slashing']];
            }

            return action;
        });
    }

    static findDamageTypes(types = '') {
        return NPC_DAMAGETYPES.filter(type => types.indexOf(type.key) >= 0).map(item => item.key);
    }

    static findConditions(conditions = '') {
        return NPC_CONDITIONS.filter(type => conditions.indexOf(type.key) >= 0).map(item => item.key);
    }

    static findLanguages(languages = '') {
        return NPC_LANGUAGES.filter(language => languages.toLowerCase().indexOf(language.key) >= 0).map(item => item.key);
    }

    static findSpellData(spells) {
        if (!spells) {
            return spells;
        }

        return spells.map(spell => {
            if (typeof spell.duration !== 'object') {
                const match = spell.duration.match('([0-9]{1,}) ([a-zA-Z]{3,})');

                if (spell.duration.toLowerCase().indexOf('concentration') > -1) {
                    spell.concentration = true;
                }

                spell.duration  = {};

                if (match) {
                    spell.duration = {
                        units: simpleSingular(match[2]),
                        value: match[1]
                    }
                } else {
                    spell.duration = {
                        units: 'inst',
                        value: null
                    }
                }
            }

            if (typeof spell.activation !== 'object') {
                const match = spell.casting_time.match('([0-9]{1,}) ([a-zA-Z]{3,})');
                spell.activation  = {};

                if (match) {
                    let activationType = simpleSingular(match[2]);
                    activationType = activationType.toLowerCase().search('bonus') > -1 ? 'bonus' : activationType;

                    spell.activation = {
                        type: activationType,
                        cost: match[1]
                    }
                }
            }

            if (typeof spell.range !== 'object') {
                const match = spell.range.toString().toLowerCase().match('([0-9]{1,}) (ft|feet)');
                let range  = {};

                if (match) {
                    const units = simpleSingular(match[2]);

                    range = {
                        units: units === 'feet' ? 'ft' : units,
                        value: match[1]
                    }
                } else {
                    range = {
                        units: spell.range.toString().toLowerCase(),
                        value: null,
                    }
                }

                spell.range = range;
            }

            if (typeof spell.target !== 'object') {
                spell.target  = {};
            }

            spell.concentration = spell.concentration || false;

            return spell;
        });
    }

    static findActionData(actions) {
        if (!actions) {
            return actions;
        }

        return actions.map(action => {
            if (typeof action.activation !== 'object') {
                action.activation  = {};
            }

            if (typeof action.duration !== 'object') {
                action.duration  = {};
            }

            if (typeof action.range !== 'object') {
                action.range = {};
            }

            if (typeof action.target !== 'object') {
                action.target = {};
            }

            return action;
        });
    }

    static findSpellsByNames(spellNames = []) {
        if (spellNames.length > 0) {
            return spellsData.filter(spell => spellNames.includes(spell.name));
        }

        return [];
    }

    static fromDataItem(item) {
        const {
            name,
            size,
            type,
            subtype,
            alignment,
            armor_class,
            hit_points,
            hit_dice,
            speed,
            strength,
            dexterity,
            constitution,
            intelligence,
            wisdom,
            charisma,
            strength_save,
            dexterity_save,
            charisma_save,
            constitution_save,
            intelligence_save,
            wisdom_save,
            history,
            perception,
            damage_vulnerabilities,
            damage_resistances,
            damage_immunities,
            condition_immunities,
            senses,
            languages = '',
            challenge_rating,
            special_abilities,
            actions,
            legendary_actions,
            spellcasting_ability,
            spells
        } = item;

        return {
            name,
            size,
            type,
            subtype,
            alignment,
            ac: armor_class,
            hp: hit_points,
            hit_dice,
            speed,
            strength,
            dexterity,
            constitution,
            intelligence,
            wisdom,
            charisma,
            strength_save,
            dexterity_save,
            charisma_save,
            constitution_save,
            intelligence_save,
            wisdom_save,
            strProficient: typeof strength_save !== 'undefined',
            dexProficient: typeof dexterity_save !== 'undefined',
            conProficient: typeof constitution_save !== 'undefined',
            intProficient: typeof intelligence_save !== 'undefined',
            wisProficient: typeof wisdom_save !== 'undefined',
            chaProficient: typeof charisma_save !== 'undefined',
            history,
            perception,
            damage_vulnerabilities,
            damage_resistances,
            damage_immunities,
            condition_immunities,
            challenge_rating,
            special_abilities,
            actions,
            legendary_actions,
            attackRoll: '',
            damageRoll: '',
            url: '',
            content: '',
            spellcasting_ability: spellcasting_ability ? spellcasting_ability.substring(0,3) : Npc.findSpellcastingAbility(special_abilities),
            skills: Npc.findSkills(item),
            speeds: Npc.findSpeeds(speed),
            senses: Npc.findSenses(senses),
            resistances: Npc.findDamageTypes(damage_resistances),
            immunities: Npc.findDamageTypes(damage_immunities),
            vulnerabilities: Npc.findDamageTypes(damage_vulnerabilities),
            conditionImmunities: Npc.findConditions(condition_immunities),
            languages: Npc.findLanguages(languages),
            spells: Npc.findSpellsByNames(spells)
        }
    }
}

export default Npc;