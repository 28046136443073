export function copyToClipboard(str) {
    const input = document.createElement('input');
    input.type = 'text';
    input.style.height = '0';
    input.value = str;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    input.remove();
};

export function downloadObjectAsJson(exportObj, exportName){
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}