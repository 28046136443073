import React from 'react';
import {connect} from 'react-redux';
import {editSkill, removeSkill} from '../../store/actions';
import {activationToText, durationToText, rangeToText} from '../helpers/text';

class SkillList extends React.Component {

    renderAttackBonus = (attackBonus) => {
        if (attackBonus && attackBonus !== 0) {
            return `Attackbonus: ${attackBonus}`;
        }

        return null;
    };

    editSkill = (type, index) => {
        this.props.editSkill(type, index);
    }

    render() {
        const {list, type, removable} = this.props;

        if (!list) {
            return null;
        }

        return (
            list.map((item, index) => {
                const hasDamageParts = item.damage_parts && item.damage_parts.length > 0;

                return (
                    <div className="card text-white bg-dark mt-3 mb-3" key={`${item.name}-${index}`}>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="mb-0 d-flex flex-row justify-content-between">
                                        <span>{item.name}</span>
                                            <span>
                                            {removable && <span>
                                                <button className="btn table-btn" type="button" onClick={() => this.props.editSkill(type, index)}>
                                                    <i className="material-icons md-light text-white edit">edit</i>
                                                </button>
                                            </span>}

                                            {removable && <span>
                                                <button className="btn table-btn text-danger" type="button" onClick={() => window.confirm(`Remove ${item.name} from skill list?`) && this.props.removeSkill(type, index)}>
                                                    <i className="material-icons md-light">close</i>
                                                </button>
                                            </span>}
                                        </span>

                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-secondary">
                            <p className="card-text">
                                {item.desc}
                            </p>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-lg-6">Activation: {activationToText(item.activation)}</div>
                                <div className="col-lg-6">Range: {rangeToText(item.range)}</div>
                            </div>
                            {hasDamageParts && <div className="border-top mt-2 pt-2">
                                {hasDamageParts && item.damage_parts.map((part, index) => {
                                    return <div className="row" key={`spell-damage-${index}`}>
                                        <div className="col-lg-12">
                                            {part[0]} {part[1]} damage
                                        </div>
                                    </div>;
                                })}
                            </div>}
                        </div>
                    </div>
                );
            })
        );

    }
}

const mapDispatchToProps = dispatch => ({
    removeSkill: (type, index) => dispatch(removeSkill(type, index)),
    editSkill: (type, index) => dispatch(editSkill(type, index)),
});

export default connect(null, mapDispatchToProps)(SkillList);