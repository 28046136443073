import React from 'react';

class SkillDuration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            units: 'action',
            value: 1
        }
    }

    handleDurationChange = (event) => {
        const {target: {value, name}} = event;

        const change = {};
        change[name] = value;

        this.setState(change, () => this.props.onDurationChange(this.state));
    };

    render() {
        const {units = 'inst', value = 1} = this.props;

        return <div className="form-row">
            <div className="form-group col-6">
                <label htmlFor="duration_type">Duration Type</label>
                <select className="form-control" id="duration_type" name="units" onChange={this.handleDurationChange} value={units}>
                    <option value="inst">Instantaneous</option>
                    <option value="round">Round</option>
                    <option value="minute">Minute</option>
                    <option value="hour">Hour</option>
                    <option value="day">Day</option>
                </select>
            </div>
            <div className="form-group col-6">
                <label htmlFor="duration_amount">Amount</label>
                <input
                    type="number"
                    className="form-control"
                    name="value"
                    id="duration_amount"
                    aria-describedby=""
                    placeholder="Amount"
                    value={units === 'inst' ? '' : value}
                    onChange={this.handleDurationChange}
                    disabled={units === 'inst'}
                />
            </div>
        </div>;
    }
}

export default SkillDuration;