import React from 'react';
import { connect } from 'react-redux';
import {importStore, showMessage} from '../store/actions';

class ImportExport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toImport: '',
        };
    }

    handleInputChange = (event) => {
        const {value} = event.target;

        this.setState({toImport: value});
    };

    base64 = (store) => {
        store = {...store};
        const {party, npcs} = store;

        const exportStore = {
            party,
            npcs,
        };

        return window.btoa(unescape(encodeURIComponent(JSON.stringify(exportStore))));
    };

    importStore = () => {
        const {toImport} = this.state;

        try {
            const store = JSON.parse(decodeURIComponent(escape(window.atob(toImport))));

            if (store) {
                const {party, npcs} = store;

                if (party && npcs) {
                    this.props.importStore(party, npcs);
                    this.props.showMessage('Party and NPCs imported');

                    return null;
                }
            }
        } catch (error) {
            this.props.showMessage('Invalid Importstring', 'danger');
        }
    };

    copyToClipboard = str => {
        const textAreaElement = document.getElementById('exportContainer');
        textAreaElement.select();
        document.execCommand('copy');

        this.props.showMessage('Export copied to clipboard');
    };

    render() {
        const {store} = this.props;
        const {toImport} = this.state;

        return (
            <>
                <div className="row pl-2">
                    <div className="col-12 bg-dark text-white p-4 pb-3 rounded shadow mt-3">
                        <form className="">
                            <div className="position-relative mb-3 mt-2">
                                <textarea id="exportContainer" className="position-absolute" style={{width: '0px', height: '0px', zIndex: -20}} value={this.base64(store)} readOnly={true} />
                                <button type="button" className="btn btn-info pl-5 position-relative" onClick={() => {
                                    this.copyToClipboard();
                                }}>
                                    <i className="material-icons">exit_to_app</i> Export to Clipboard
                                </button>
                            </div>
                            <div className="pt-2 pb-2">
                                Paste your exported data below. <strong>Warning!</strong> Importing data from another instance will clear all current party and npc data.
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <label htmlFor="import">Import</label>
                                    <textarea className="form-control" name="import" id="import" rows="5" value={toImport} onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </form>
                        <button type="button" className="btn btn-info pl-5 position-relative" onClick={() => {
                            this.importStore();
                        }}>
                            <i className="material-icons">transit_enterexit</i> Import
                        </button>
                    </div>
                </div>

                <div className="row pl-2">
                    <div className="col-12 bg-dark text-white p-4 pb-3 rounded shadow mt-3">
                        <h3 className="mb-6">Used Resources</h3>

                        <ul style={{marginTop: '30px'}}>
                            <li>
                                <a href="https://www.reddit.com/r/dndnext/comments/43a09o/srd_monsters_in_json_format/" target="__blank">
                                    Monster JSON dataset posted by /u/droiddruid
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/vorpalhex/srd_spells" target="__blank">
                                    Spells JSON dataset
                                </a>
                            </li>
                            <li>
                                <a href="https://www.reddit.com/r/DnDBehindTheScreen/comments/2wm2bn/improved_printable_random_name_generator_now/" target="__blank">
                                    Random name generation sheet by /u/boltorian
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/ScottMaclure/character-traits" target="__blank">
                                    Character traits JSON dataset
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </>

        );
    }
}

const mapStateToProps = state => {
    return {store: state};
};

const mapDispatchToProps = dispatch => ({
    importStore: (party, npcs) => dispatch(importStore(party, npcs)),
    showMessage: (message, type) => dispatch(showMessage(message, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportExport);