export const NPC_SKILLS = [
    {
        name: 'Acrobatics',
        itemKey: 'acrobatics',
        key: 'acr',
        ability: 'dex',
    },
    {
        name: 'Animal Handling',
        itemKey: 'animal_handling',
        key: 'ani',
        ability: 'wis',
    },
    {
        name: 'Arcana',
        itemKey: 'arcana',
        key: 'arc',
        ability: 'int',
    },
    {
        name: 'Athletics',
        itemKey: 'athletics',
        key: 'ath',
        ability: 'str',
    },
    {
        name: 'Deception',
        itemKey: 'deception',
        key: 'dec',
        ability: 'cha',
    },
    {
        name: 'History',
        itemKey: 'history',
        key: 'his',
        ability: 'int',
    },
    {
        name: 'Insight',
        itemKey: 'insight',
        key: 'ins',
        ability: 'wis',
    },
    {
        name: 'Intimidation',
        itemKey: 'intimidation',
        key: 'itm',
        ability: 'cha',
    },
    {
        name: 'Investigation',
        itemKey: 'investigation',
        key: 'inv',
        ability: 'int',
    },
    {
        name: 'Medicine',
        itemKey: 'medicine',
        key: 'med',
        ability: 'wis',
    },
    {
        name: 'Nature',
        itemKey: 'nature',
        key: 'nat',
        ability: 'int',
    },
    {
        name: 'Perception',
        itemKey: 'perception',
        key: 'prc',
        ability: 'wis',
    },
    {
        name: 'Performance',
        itemKey: 'performance',
        key: 'prf',
        ability: 'cha',
    },
    {
        name: 'Persuasion',
        itemKey: 'persuasion',
        key: 'per',
        ability: 'cha',
    },
    {
        name: 'Religion',
        itemKey: 'religion',
        key: 'rel',
        ability: 'int',
    },
    {
        name: 'Sleight of Hand',
        itemKey: 'sleight_of_hand',
        key: 'slt',
        ability: 'dex',
    },
    {
        name: 'Stealth',
        itemKey: 'stealth',
        key: 'ste',
        ability: 'dex',
    },
    {
        name: 'Survival',
        itemKey: 'survival',
        key: 'sur',
        ability: 'wis',
    },
];

export const NPC_DAMAGETYPES = [
    {
        name: 'Slashing',
        key: 'slashing',
    },
    {
        name: 'Piercing',
        key: 'piercing',
    },
    {
        name: 'Bludgeoning',
        key: 'bludgeoning',
    },
    {
        name: 'Acid',
        key: 'acid',
    },
    {
        name: 'Cold',
        key: 'cold',
    },
    {
        name: 'Fire',
        key: 'fire',
    },
    {
        name: 'Force',
        key: 'force',
    },
    {
        name: 'Lightning',
        key: 'lightning',
    },
    {
        name: 'Necrotic',
        key: 'necrotic',
    },
    {
        name: 'Poison',
        key: 'poison',
    },
    {
        name: 'Psychic',
        key: 'psychic',
    },
    {
        name: 'Radiant',
        key: 'radiant',
    },
    {
        name: 'Thunder',
        key: 'thunder',
    },
];

export const NPC_CONDITIONS = [
    {
        name: 'Blinded',
        key: 'blinded',
    },
    {
        name: 'Charmed',
        key: 'charmed',
    },
    {
        name: 'Deafened',
        key: 'deafened',
    },
    {
        name: 'Frightened',
        key: 'frightened',
    },
    {
        name: 'Grappled',
        key: 'grappled',
    },
    {
        name: 'Incapacitated',
        key: 'incapacitated',
    },
    {
        name: 'Invisible',
        key: 'invisible',
    },
    {
        name: 'Paralyzed',
        key: 'paralyzed',
    },
    {
        name: 'Petrified',
        key: 'petrified',
    },
    {
        name: 'Poisoned',
        key: 'poisoned',
    },
    {
        name: 'Prone',
        key: 'prone',
    },
    {
        name: 'Restrained',
        key: 'restrained',
    },
    {
        name: 'Stunned',
        key: 'stunned',
    },
    {
        name: 'Unconscious',
        key: 'unconscious',
    },
    {
        name: 'Exhaustion',
        key: 'exhaustion',
    },
];

export const NPC_TYPES = [
    {
        name: 'Aberration',
        key: 'aberration'
    },
    {
        name: 'Beast',
        key: 'beast'
    },
    {
        name: 'Celestial',
        key: 'celestial'
    },
    {
        name: 'Construct',
        key: 'construct'
    },
    {
        name: 'Dragon',
        key: 'dragon'
    },
    {
        name: 'Elemental',
        key: 'elemental'
    },
    {
        name: 'Fey',
        key: 'fey'
    },
    {
        name: 'Fiend',
        key: 'fiend'
    },
    {
        name: 'Giant',
        key: 'giant'
    },
    {
        name: 'Humanoid',
        key: 'humanoid'
    },
    {
        name: 'Monstrosity',
        key: 'monstrosity'
    },
    {
        name: 'Ooze',
        key: 'ooze'
    },
    {
        name: 'Plant',
        key: 'plant'
    },
    {
        name: 'Undead',
        key: 'undead'
    },
];

export const NPC_LANGUAGES = [
    {
        name: 'Abyssal',
        key: 'abyssal'
    },
    {
        name: 'Celestial',
        key: 'celestial'
    },
    {
        name: 'Common',
        key: 'common'
    },
    {
        name: 'Deep',
        key: 'deep'
    },
    {
        name: 'Draconic',
        key: 'draconic'
    },
    {
        name: 'Dwarvish',
        key: 'dwarvish'
    },
    {
        name: 'Elvish',
        key: 'elvish'
    },
    {
        name: 'Giant',
        key: 'giant'
    },
    {
        name: 'Gnomish',
        key: 'gnomish'
    },
    {
        name: 'Goblin',
        key: 'goblin'
    },
    {
        name: 'Halfling',
        key: 'halfling'
    },
    {
        name: 'Infernal',
        key: 'infernal'
    },
    {
        name: 'Orc',
        key: 'orc'
    },
    {
        name: 'Primordial',
        key: 'primordial'
    },
    {
        name: 'Sylvan',
        key: 'sylvan'
    },
    {
        name: 'Undercommon',
        key: 'undercommon'
    }
];
