import React from 'react';

const TARGET_TYPES_WITH_RANGE = ['radius', 'line', 'cone', 'wall', 'cube', 'sphere'];
const TARGET_WITH_MULTIPLES = ['creature', 'object', 'space'];
const TYPES = [
    {
        type: 'creature',
        label: 'Creature'
    },
    {
        type: 'object',
        label: 'Object'
    },
    {
        type: 'self',
        label: 'Self'
    },
    {
        type: 'space',
        label: 'Space'
    },
    {
        type: 'radius',
        label: 'Radius'
    },
    {
        type: 'line',
        label: 'Line'
    },
    {
        type: 'cone',
        label: 'Cone'
    },
    {
        type: 'wall',
        label: 'Wall'
    },
    {
        type: 'cube',
        label: 'Cube'
    },
    {
        type: 'sphere',
        label: 'Sphere'
    }
];

class SkillRange extends React.Component {

    constructor(props) {
        super(props);

        this.rangeRef = React.createRef();
        this.targetRef = React.createRef();

        this.state = {
            range: {
                value: 5,
                long: 0,
                units: 'touch'
            },
            target: {
                value: '',
                width: null,
                units: 'ft',
                type: 'creature'
            }
        };
    }

    componentDidMount() {
        const {range, target} = this.props;

        this.populateState(range, target);
    }

    componentDidUpdate(prevProps) {
        const {index, range, target} = this.props;

        if (prevProps.range !== range || prevProps.target !== target) {
            // if (prevProps.target.type === '' && target.type !== '') {
            //     target.value = 1;
            // }
            //
            this.populateState(range, target);
        }

        if (prevProps.index !== index) {
            this.populateState(range, target);
        }
    }

    populateState = (range = {}, target = {}) => {
        const {allowEmpty} = this.props;

        const defaultRange = allowEmpty ? '' : 'touch';
        const defaultTarget = allowEmpty ? '' : 'creature';
        const defaultTargetValue = allowEmpty ? '' : 1;

        this.setState({
            range: {
                value: range.value || this.hasRangeFocus() ? range.value : 5,
                long: range.long || 0,
                units: range.units || defaultRange
            },
            target: {
                value: target.value || this.hasTargetFocus() ? target.value : defaultTargetValue,
                width: target.width || null,
                units: target.units || 'ft',
                type: target.type || defaultTarget
            }
        });
    };

    hasRangeFocus = () => this.targetRef.current === document.activeElement;
    hasTargetFocus = () => this.targetRef.current === document.activeElement;

    handleRangeChange = event => {
        const {target: {name, value}} = event;
        const {range} = this.state;

        range[name] = value;
        this.setState({range: {...range}}, () => {
            if (range.units !== 'ft') {
                range.value = null;
            }

            this.props.onChangeRange(range);
        });
    };

    handleTargetChange = event => {
        const {target: {name, value}} = event;
        const {target} = this.state;
        const typeHasChanged = name === 'type' && target.type !== value;


        target[name] = value;
        this.setState({target: {...target}}, () => {
            if (!target.type) {
                target.value = '';
            } else if (typeHasChanged && !this.hasTargetFocus()) {
                if (TARGET_WITH_MULTIPLES.includes(value)) {
                    target.value = 1;
                } else if (TARGET_TYPES_WITH_RANGE.includes(value)) {
                    target.value = 5;
                } else {
                    target.value = '';
                }
            }

            this.props.onChangeTarget(target)
        });
    };

    renderRangeForm = () => {
        const {range} = this.state;
        const hasRange = range.units === 'ft';

        return <div className="form-row">
            <div className="form-group col-6">
                <label htmlFor="range_type">Range Type</label>
                <select className="form-control" id="range_type" name="units" onChange={this.handleRangeChange} value={range.units}>
                    {this.props.allowEmpty && <option value={''} />}
                    <option value="touch">Touch</option>
                    <option value="self">Self</option>
                    <option value="ft">Range</option>
                </select>
            </div>
            <div className="form-group col-6 form-ft">
                <label htmlFor="range_value">Range</label>
                <input ref={this.rangeRef} type="number" className="form-control" name="value" id="range_value" aria-describedby="" placeholder="Range" value={hasRange ? range.value : ''} onChange={this.handleRangeChange} disabled={!hasRange} />
            </div>
        </div>;
    };

    renderTargetForm = () => {
        const {target} = this.state;
        const hasTarget = TARGET_TYPES_WITH_RANGE.includes(target.type);
        const hasMultiple = TARGET_WITH_MULTIPLES.includes(target.type);

        return <div className="form-row">
            <div className="form-group col-6">
                <label htmlFor="target_type">Target Type</label>
                <select className="form-control" id="target_type" name="type" onChange={this.handleTargetChange} value={target.type}>
                    {this.props.allowEmpty && <option value={''} />}
                    {TYPES.map(type => <option key={`target-type-${type.type}`} value={type.type}>{type.label}</option>)}
                </select>
            </div>
            <div className={`form-group col-6 ${hasTarget ? 'form-ft' : ''}`}>
                <label htmlFor="target_value">{hasTarget ? 'Range' : 'Amount'}</label>
                <input ref={this.targetRef} type="number" className="form-control" name="value" id="target_value" aria-describedby="" placeholder={hasTarget ? 'Range' : 'Amount'} value={target.value || ''} onChange={this.handleTargetChange} disabled={!hasTarget && !hasMultiple} />
            </div>
        </div>;
    };

    render() {
        return <>
            {this.renderRangeForm()}
            {this.renderTargetForm()}
        </>;
    }
}

export default SkillRange;