import React from 'react';

class Message extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            message: '',
            type: '',
        }
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.timestamp !== this.props.timestamp) {
            setTimeout(() => {this.setState({show: false})}, 3000);
            this.setState({
                message: this.props.message,
                type: this.props.type,
                show: true,
            });
        }

    };

    render() {
        const {message, type, show} = this.state;

        if (message && show) {
            return (
                <div className="position-fixed" style={{top: '10px', right: '10px', zIndex: 1080}}>
                    <div className={`alert alert-${type}`} role="alert">
                        {message}
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default Message;