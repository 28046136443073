import Player from '../models/player';
import {initialState, initialNpc} from './reducer';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        const state = {...initialState, ...JSON.parse(serializedState)};

        for (let index in state.party) {
            state.party[index] = Player.fromObject(state.party[index]);
        }

        state.npcMode = 'edit';
        state.npc = {...initialNpc};
        state.skillType = '';
        state.editSkillIndex = null;
        state.dirtyNpc = false;
        state.popover = '';

        return state;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};