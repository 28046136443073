import React from 'react';

const names = require('../dataset/nameParts');
const shops = require('../dataset/shops');
const traits = require('../dataset/traits');
const firstNames = require('../dataset/firstNames');

/**
 * Implementation of Boltorian's D&D Random Name Generator
 *
 * Traits dataset: http://scott.maclure.info/character-traits/traits-default.json
 */
class NameGenerator extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            male: [],
            female: [],
            shop: [],
            maleTraits: {},
            femaleTraits: {}
        };
    }

    rnd = nr => Math.floor(Math.random() * nr);

    getRandomElement = (list) => list[this.rnd(list.length)];

    generateFirstName = (firstParts, middleParts, lastParts) => {
        let validName = false;
        let name = '';

        while (validName === false) {
            name = `${this.getRandomElement(firstParts)}${this.getRandomElement(middleParts)}${this.getRandomElement(lastParts)}`;
            validName = name.length <= 8;
        }

        return name;
    };

    generateFemaleName = () => {
        const methods = [
            () => this.generateFirstName(names.start, names.middle, names.end.female),
            () => this.getRandomElement(firstNames.female),
        ];

        return `${this.getRandomElement(methods)()} ${this.generateLastName()}`;
    };

    generateMaleName = () => {
        const methods = [
            () => this.generateFirstName(names.start, names.middle, names.end.male),
            () => this.getRandomElement(firstNames.male),
        ];

        return `${this.getRandomElement(methods)()} ${this.generateLastName()}`;
    };

    generateLastName = () => {
        const randomStart = names.start[this.rnd(names.start.length)];
        const randomMiddle = names.middle[this.rnd(names.middle.length)];
        const randomEnd = names.end.male[this.rnd(names.end.male.length)];

        return `${randomStart}${randomMiddle}${randomEnd}`;
    };

    generateNames = (amount = 1, callback) => {
        const names = [];

        for (let i = 0; i < amount; i++) {
            names.push(callback());
        }

        return names;
    };

    generateShopname = () => {
        const types = ['animals', 'animals', 'nouns', 'combat', 'profession', 'magic'];
        const randomType = this.getRandomElement(types);
        const randomStart = this.getRandomElement(shops.adjectives);
        const randomEnd = this.getRandomElement(shops[randomType]);

        return `The ${randomStart} ${randomEnd}`;
    };

    generateTraits = () => {
        return {
            speech: this.getRandomElement(traits.speech),
            body: this.getRandomElement(traits.body),
            hair: this.getRandomElement(traits.hair),
            face: this.getRandomElement(traits.facialFeatures),
            characteristics: this.getCharacteristic(),
            personality: this.getRandomElement(traits.personality),
            instinct: this.getRandomElement(traits.instincts),
            knacks: this.getRandomElement(traits.knacks),
        }
    };

    getCharacteristic = () => {
        const feature = this.getRandomElement(traits.characteristics);

        if (feature.hasLocation) {
            feature.location = this.getRandomElement(traits.bodyLocations);
        }

        return feature;
    };

    regenerate = () => {
        this.setState({
            male: this.generateNames(10, this.generateMaleName),
            female: this.generateNames(10, this.generateFemaleName),
            maleTraits: this.generateTraits(),
            femaleTraits: this.generateTraits()
        });
    };

    regenerateShop = () => {
        this.setState({
            shop: this.generateNames(10, this.generateShopname),
        });
    };

    componentDidMount() {
        this.regenerate();
        this.regenerateShop();
    }

    renderTraits = (traits, pronoun) => {

        return (
            <div className=" bg-dark text-white rounded shadow p-3 mt-3">
                <h3 className="mb-4">Traits</h3>
                <table className="table table-dark table-sm">
                    <tbody>
                        <tr>
                            <td className="">Speech:</td>
                            <td>{traits.speech}</td>
                        </tr>
                        <tr>
                            <td className="">Bodytype:</td>
                            <td>{traits.body}</td>
                        </tr>
                        <tr>
                            <td className="">Hair:</td>
                            <td>{traits.hair}</td>
                        </tr>
                        <tr>
                            <td className="">Face:</td>
                            <td>{traits.face}</td>
                        </tr>
                        <tr>
                            <td className="">Characteristic:</td>
                            <td>{traits.characteristics && traits.characteristics.text} {traits.characteristics && traits.characteristics.hasLocation ? `on ${pronoun} ${traits.characteristics.location}` : ''}</td>
                        </tr>
                        <tr>
                            <td className="">Personality:</td>
                            <td>{traits.personality}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>Wants {traits.instinct && traits.instinct.toLowerCase()}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>Interested in {traits.knacks && traits.knacks.toLowerCase()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };


render() {
        const {male, female, shop, maleTraits, femaleTraits} = this.state;

        return (
            <>
                <div className="row pl-2">
                    <div className="col-12 col-lg-6 pl-0 pb-3 mt-3 pr-0">
                        <div className="bg-dark text-white rounded shadow p-3">
                            <h3 className="mb-4">Male</h3>
                            {male.map(name => {
                                return <div key={name}>{name}</div>
                            })}
                        </div>

                        {this.renderTraits(maleTraits, 'his')}
                    </div>
                    <div className="col-12 col-lg-6 pr-0 pl-0 pl-lg-2 pb-3 mt-3 pr-0">
                        <div className="bg-dark text-white rounded shadow p-3">
                            <h3 className="mb-4">Female</h3>
                            {female.map(name => {
                                return <div key={name}>{name}</div>
                            })}
                        </div>

                        {this.renderTraits(femaleTraits, 'her')}
                    </div>
                    <button className="mt-3 mb-3 btn btn-danger pl-5 position-relative" onClick={this.regenerate}>
                        <i className="material-icons">autorenew</i>Renew
                    </button>
                </div>
                <div className="row pl-2">
                    <div className="col-12 col-lg-6 pl-0 pb-3 mt-3 pr-0">
                        <div className=" bg-dark text-white rounded shadow p-3">
                            <h3 className="mb-4">Shops</h3>
                            {shop.map(name => {
                                return <div key={name} className="text-capitalize">{name}</div>
                            })}
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0 m-0">

                    </div>
                    <button className="mt-3 mb-3 btn btn-danger pl-5 position-relative" onClick={this.regenerateShop}>
                        <i className="material-icons">autorenew</i>Renew
                    </button>
                </div>
            </>
        );
    }
}

export default NameGenerator;