export const SWITCH_CONTEXT = 'SWITCH_CONTEXT';
export const SET_MESSAGE = 'SET_MESSAGE';

export const ADD_CONTENDER = 'ADD_CONTENDER';
export const REMOVE_CONTENDER = 'REMOVE_CONTENDER';
export const UPDATE_CONTENDER = 'UPDATE_CONTENDER';
export const RESET_CONTENDERS = 'RESET_CONTENDERS';

export const ADD_PLAYER = 'ADD_PLAYER';
export const REMOVE_PLAYER = 'REMOVE_PLAYER';
export const EDIT_PLAYER = 'EDIT_PLAYER';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';

export const ADD_NPC = 'ADD_NPC';
export const REMOVE_NPC = 'REMOVE_NPC';
export const EDIT_NPC = 'EDIT_NPC';
export const UPDATE_NPC = 'UPDATE_NPC';
export const VIEW_NPC = 'VIEW_NPC';
export const CLEAR_NPC = 'CLEAR_NPC';
export const SWITCH_NPC_MODE = 'SWITCH_NPC_MODE';

export const NAVIGATE_TO_NPC = 'NAVIGATE_TO_NPC';

export const SHOW_NPC_SKILL_FORM = 'SHOW_NPC_SKILL_FORM';
export const ADD_NPC_SKILL = 'ADD_NPC_SKILL';
export const EDIT_NPC_SKILL = 'EDIT_NPC_SKILL';
export const UPDATE_NPC_SKILL = 'UPDATE_NPC_SKILL';
export const REMOVE_NPC_SKILL = 'REMOVE_NPC_SKILL';
export const EDIT_NPC_SPELL = 'EDIT_NPC_SPELL';
export const SET_NPC_DIRTY = 'SET_NPC_DIRTY';
export const UPDATE_NPC_SPELL = 'UPDATE_NPC_SPELL';

export const IMPORT_STORE = 'IMPORT_STORE';