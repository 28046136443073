import React from 'react';
import {connect} from 'react-redux';
import {editSkill, removeSkill} from '../store/actions';
import {activationToText, durationToText, rangeToText} from './helpers/text';


class Spell extends React.Component {

    render() {
        const {spell, removable, index} = this.props;
        const hasDamageParts = spell.damage && spell.damage.parts && spell.damage.parts.length > 0;

        return (
            <div className="card text-white bg-dark mb-3">
                <div className="card-header">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="mb-0 d-flex flex-row justify-content-between">
                                <span className="spell-name">{spell.name}{spell.concentration && <i className="material-icons md-light ml-2" title="Concentration">offline_bolt</i>}</span>
                                <div>
                                    {removable && <span>
                                        <button className="btn table-btn text-white" type="button" onClick={() => this.props.editSkill('spells', index)}>
                                            <i className="material-icons md-light edit">edit</i>
                                        </button>
                                    </span>}

                                    {removable && <span>
                                        <button className="btn table-btn text-danger" type="button" onClick={() => window.confirm(`Remove ${spell.name} from spell list?`) && this.props.removeSkill('spells', index)}>
                                            <i className="material-icons md-light">close</i>
                                        </button>
                                    </span>}
                                </div>
                            </h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-10">
                            <small>Lvl: {spell.level}</small>
                        </div>
                        <div className="col-2">
                            <small>{spell.ritual ? 'Ritual' : ''}</small>
                        </div>
                    </div>

                </div>
                <div className="card-body bg-secondary">
                    <p className="card-text">
                        {spell.description}
                    </p>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-lg-4">Casting time: {activationToText(spell.activation)}</div>
                        <div className="col-lg-4">Range: {rangeToText(spell.range)}</div>
                        <div className="col-lg-4">Duration: {durationToText(spell.duration)}</div>
                    </div>

                    {hasDamageParts && <div className="border-top mt-2 pt-2">
                        {hasDamageParts && spell.damage.parts.map((part, index) => {
                            return <div className="row" key={`spell-damage-${index}`}>
                                <div className="col-lg-12">
                                    {part[0]} {part[1]} damage
                                </div>
                            </div>;
                        })}
                    </div>}

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    removeSkill: (type, index) => dispatch(removeSkill(type, index)),
    editSkill: (type, index) => dispatch(editSkill(type, index)),
});

export default connect(null, mapDispatchToProps)(Spell);