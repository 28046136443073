import React from 'react';

const eventData = require('../../dataset/events');

class GeneratorView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            events: [],
        }
    }

    componentDidMount() {
        this.generateEvents();
    }

    rnd = nr => Math.floor(Math.random() * nr);

    generateEvents = (amount = 3) => {
        const randomEvents = [];

        for (let i = 0; i < amount; i++) {
            const location = eventData.location[this.rnd(eventData.location.length)];
            const element = eventData.element[this.rnd(eventData.element.length)];
            const interaction = eventData.interaction[this.rnd(eventData.interaction.length)];

            randomEvents.push({location, element, interaction});
        }

        this.setState({events: randomEvents});
    };

    renderEvent = event => {
        const {location, element, interaction} = event;

        return <><span>{location}</span> <span>{element}</span> <span>{interaction}</span></>;
    }

    render() {
        const {events} = this.state;

        return (
            <>
                <div className="row pl-2">
                    <div className="col-12 pl-0 pb-3 mt-3 pr-0">
                        <div className="bg-dark text-white rounded shadow p-3">
                            <h3 className="mb-4">Random Outdoor Events</h3>
                            {events.map((event, index) => {
                                return <p className="generated-event" key={`random-event-${index}`}>{this.renderEvent(event)}</p>
                            })}
                        </div>
                    </div>
                    <button className="mt-3 mb-3 btn btn-danger pl-5 position-relative" onClick={() => this.generateEvents()}>
                        <i className="material-icons">autorenew</i>Renew
                    </button>
                </div>
            </>
        );
    }

}

export default GeneratorView;