import React from 'react';
import { connect } from 'react-redux';
import {switchContext} from '../store/actions';
import ContenderForm from './contender/contenderForm';
import ContenderList from './contender/contenderList';
import PlayerForm from './party/playerForm';
import PartyList from './party/partyList';
import Message from './message';
import NpcList from './npc/npcList';
import NpcForm from './npc/npcForm';
import NpcView from './npc/npcView';
import Spells from './spells';
import ImportExport from './importExport';
import NameGenerator from './nameGenerator';
import GeneratorView from './generator/generatorView';

class InitiativeList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            message: '',
        }
    }

    setTab = (tab) => {
        this.props.switchContext(tab);
    };

    showMessage = (message, type) => {
        this.setState({message: {
            message: message,
            type: type,
            timestamp: Date.now(),
        }});
    };

    render() {
        const {message, tab, npcMode} = this.props;

        return (
            <div>
                <Message message={message.message} type={message.type} timestamp={message.timestamp} />
                <div className="row pt-3 pb-0 d-flex flex-row justify-content-between">
                    <div className="col-3 pl-2">
                        <div className="tab-btns btn-group alert mb-0" role="group" aria-label="Visible Tab">
                            <button type="button" onClick={() => this.setTab('initiative')} className={`btn ${tab === 'initiative' ? 'btn-primary' : 'btn-secondary'}`}>Encounter</button>
                            <button type="button" onClick={() => this.setTab('party')} className={`btn ${tab === 'party' ? 'btn-primary' : 'btn-secondary'}`}>Party</button>
                            <button type="button" onClick={() => this.setTab('npcs')} className={`btn ${tab === 'npcs' ? 'btn-primary' : 'btn-secondary'}`}>NPCs</button>
                            <button type="button" onClick={() => this.setTab('spells')} className={`btn ${tab === 'spells' ? 'btn-primary' : 'btn-secondary'}`}>Spells</button>
                            <button type="button" onClick={() => this.setTab('names')} className={`btn ${tab === 'names' ? 'btn-primary' : 'btn-secondary'}`}>Names</button>
                            <button type="button" onClick={() => this.setTab('events')} className={`btn ${tab === 'events' ? 'btn-primary' : 'btn-secondary'}`}>Events</button>
                        </div>
                    </div>
                    <div className="tab-btns btn-group alert mb-0 pr-0 d-flex flex-row-reverse" role="group" aria-label="Visible Tab">
                        <button type="button" onClick={() => this.setTab('import')} className={`btn position-relative pl-3 pr-4 ${tab === 'import' ? 'btn-primary' : 'btn-info'}`}>
                            <i className="position-absolute material-icons md-light" style={{left: '8px', top: '6px'}}>settings</i>
                        </button>
                    </div>
                </div>

                {tab === 'party' && <div className="row pt-3 pb-4 pl-2">
                    <div className="w-100">
                        <PartyList showMessage={this.showMessage} />
                    </div>
                    <div className="w-100">
                        <PlayerForm
                            updatePlayer={this.updatePlayer} 
                            clearPlayer={this.clearPlayer}
                        />
                    </div>                    
                </div>}

                {tab === 'initiative' && <div className="row pt-3 pb-4">
                    <div className="col-lg-3 pl-2 pr-0 pr-lg-3">
                        <ContenderForm />
                    </div>
                    <div className="col-lg-9 pl-2 pr-0">
                        <ContenderList />
                    </div>
                </div>}

                {tab === 'npcs' && <div className="row pt-3 pb-4">
                    <div className="col-lg-4 pl-2 pr-0">
                        <NpcList />
                    </div>
                    <div className="col-lg-8 col-12 mt-2 mt-lg-0 pr-0 pl-2">
                        {npcMode === 'edit' && <NpcForm />}
                        {npcMode === 'view' && <NpcView />}
                    </div>
                </div>}

                {tab === 'spells' && <Spells />}
                {tab === 'import' && <ImportExport />}
                {tab === 'names' && <NameGenerator />}
                {tab === 'events' && <GeneratorView />}
            </div>
        );
    }

}

const mapStateToProps = state => {
    const {context, message, npcMode} = state;

    return {tab: context, message, npcMode};
};

const mapDispatchToProps = dispatch => ({
    switchContext: context => dispatch(switchContext(context)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InitiativeList);