import Model from './model';

class Contender extends Model {

    constructor(name, ac, hp, type = 'contender', npcId = null, dexterity = 10) {
        super();
        this.id = this.createRandomString(16);
        this.name = name;
        this.ac = ac;
        this.hp = hp;
        this.initiative = this.randomNumber(1, 20) + this.calcInitiativeModifierFromDex(dexterity);
        this.changeValue = '';
        this.type = type;
        this.npcId = npcId;
        this.color = this.createRandomColor();
    }

    calcInitiativeModifierFromDex(dexterity = 10) {
        return Math.floor((parseInt(dexterity) - 10) / 2);
    }

    createRandomColor() {
        const r = this.decimalToHex(Math.floor(Math.random() * 255));
        const g = this.decimalToHex(Math.floor(Math.random() * 255));
        const b = this.decimalToHex(Math.floor(Math.random() * 255));

        return `#${r}${g}${b}`;
    }

    decimalToHex(nr) {
        let hex = Number(nr).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    };
}

export default Contender;