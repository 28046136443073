export function sortContenders(contenders) {
    contenders.sort((contender1, contender2) => {
        return contender2.initiative - contender1.initiative;
    });

    return contenders;
}

export function createRandomString(length = 16) {
    var str = "";
    for (; str.length < length; str += Math.random().toString(36).substr(2));
    return str.substr(0, length);
}