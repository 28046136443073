import React from 'react';
import {NPC_DAMAGETYPES} from './npcConstants';
import Popover from '../popover';
import DiceSelector from './diceSelector';
import {findDamageBonusFromDamage, findRollsFromDamage} from '../helpers/rolls';
import {areEqual} from '../helpers/array';

class SkillDamageRolls extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            parts: [],
            popover: '',
            diceIndex: 0,
        }
    }

    componentDidMount() {
        const {parts} = this.props;

        const damageParts = parts.map(part => ({
            type: part[1],
            rolls: findRollsFromDamage(part[0]),
            bonus: findDamageBonusFromDamage(part[0]),
        }));

        this.setState({parts: damageParts});
    }

    componentDidUpdate(prevProps, prevState) {
        const {parts} = this.props;

        if (!areEqual(parts, prevProps.parts)) {
            const damageParts = parts.map(part => ({
                type: part[1],
                rolls: findRollsFromDamage(part[0]),
                bonus: findDamageBonusFromDamage(part[0]) || '',
            }));

            this.setState({parts: damageParts});
        }
    }

    renderDamageDropdown = (id, onChange, value = '') => {
        return <select className="form-control" onChange={onChange} value={value}>
            <option value="">None</option>
            {NPC_DAMAGETYPES.map(type => {
                const {name, key} = type;

                return <option key={`${id}-${key}`} value={key}>
                    {name}
                </option>;
            })}
        </select>
    };

    onChange = (event, index) => {
        const {target: {value}} = event;
        const {parts} = this.state;

        if (value === '') {
            parts.splice(index, 1);
        } else {
            parts[index].type = value;
        }

        this.setState({parts: [...parts]}, () => this.props.onChange(parts));
    };

    onNew = (event) => {
        const {target: {value}} = event;
        const {parts} = this.state;

        if (value !== '') {
            parts.push({type: value, rolls: '', bonus: 0});

            this.setState({parts: [...parts]}, () => this.props.onChange(parts));
        }
    };

    updateDamageBonus = (event, index) => {
        const {target: {value}} = event;
        const {parts} = this.state;

        parts[index].bonus = value;

        this.setState({parts: [...parts]}, () => this.props.onChange(parts));
    }

    updateDiceRolls = value => {
        const {parts, diceIndex} = this.state;

        parts[diceIndex].rolls = value;

        this.setState({parts: [...parts], popover: ''},  () => this.props.onChange(parts));
    };

    render() {
        const {parts, popover, diceIndex} = this.state;

        return <>
            <div className="form-row">
                <div className="form-group col-4 mb-0">
                    <label>Damage Type</label>
                </div>
                <div className="form-group col-4 mb-0">
                    <label>Rolls</label>
                </div>
                <div className="form-group col-4 mb-0">
                    <label>Bonus</label>
                </div>
            </div>

            {parts.map((part, index) => {
                return <div className="form-row" key={`part-${index}`}>
                    <div className="form-group col-4">
                        {this.renderDamageDropdown(`part-${index}`, event => this.onChange(event, index), part.type)}
                    </div>
                    <div className="form-group col-4">
                        <input type="text" className="form-control" id={`part-damageroll-${index}`} placeholder="Damage Dice" value={part.rolls} onClick={() => this.setState({'popover': 'damageroll', diceIndex: index})} readOnly={true} />
                    </div>
                    <div className="form-group col-4">
                        <input type="text" className="form-control" id={`part-damagebonus-${index}`} placeholder="Damage Bonus" value={part.bonus} onChange={event => this.updateDamageBonus(event, index)} />
                    </div>
                </div>;
            })}

            <div className="form-row">
                <div className="form-group col-4">
                    {this.renderDamageDropdown('new', this.onNew)}
                </div>
                <div className="form-group col-8" />
            </div>

            {popover === 'damageroll' && <Popover title="Damage Dice" close={() => this.setState({popover: ''})}>
                <DiceSelector value={parts[diceIndex].rolls} onSelect={this.updateDiceRolls} />
            </Popover>}
        </>;
    }
}

export default SkillDamageRolls;