import React from 'react';
import {getColumns} from '../helpers/form';
import {NPC_SKILLS} from './npcConstants';



class SkillProficiencyList extends React.Component {

    renderSkills = skills => {
        return skills.map(skill => <div className="form-group col-4" key={`skill-${skill.key}`}>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" name="skills" value={skill.key} id={`skill${skill.key}Check`} checked={this.props.hasSkill(skill.key)} onChange={this.props.changeSkill} />
                <label className="form-check-label" htmlFor={`skill${skill.key}Check`}>
                    {skill.name}
                </label>
            </div>
        </div>);
    }

    render() {
        const skills = getColumns(NPC_SKILLS, 3)

        return skills.map((row, index) => <div className="form-row" key={`skillrow-${index}`}>
            {this.renderSkills(row)}
        </div>);
    }

}

export default SkillProficiencyList;