import React from 'react';
import {connect} from 'react-redux';
import {addNpc, clearNpc, editNpc, showMessage, updateNpc} from '../../store/actions';
import MonsterSearch from './monsterSearch';
import {createRandomString} from '../../store/helpers';
import NpcSkills from './npcSkills';
import SkillProficiencyList from './skillProficiencyList';
import Popover from '../popover';
import DamageTypeList from './damageTypeList';
import {addValueToList, getDistances, getProficiencies, inList} from '../helpers/form';
import ConditionTypeList from './conditionTypeList';
import {initialNpc} from '../../store/reducer';
import SpeedForm from './speedForm';
import SensesForm from './sensesForm';
import {NPC_TYPES} from './npcConstants';
import LanguageList from './languageList';
import Npc from '../../models/npc';
import {exportNpcToFoundry} from '../helpers/foundry';
import {copyToClipboard} from '../helpers/ui';
import Icon from '@mdi/react';
import {mdiDiceD20Outline} from '@mdi/js';

class NpcForm extends React.Component {

    constructor(props) {
        super(props);

        this.initState = {
            npc: {...initialNpc},
            popover: '',
        };

        this.state = this.getInitState();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const {npc} = this.state;

        if (target.type === 'checkbox') {
            npc[name] = target.checked;
        } else {
            npc[name] = target.value;
        }

        this.syncNpc(npc);
    };

    changeSkill = event => {
        const {npc} = this.state;

        npc.skills = addValueToList(event, npc.skills);
        this.syncNpc(npc);
    };

    changeResistance = event => {
        const {npc} = this.state;

        npc.resistances = addValueToList(event, npc.resistances);
        this.syncNpc(npc);
    };

    changeImmunity = event => {
        const {npc} = this.state;

        npc.immunities = addValueToList(event, npc.immunities);
        this.syncNpc(npc);
    };

    changeVulnerability = event => {
        const {npc} = this.state;

        npc.vulnerabilities = addValueToList(event, npc.vulnerabilities);
        this.syncNpc(npc);
    };

    changeConditionImmunity = event => {
        const {npc} = this.state;

        npc.conditionImmunities = addValueToList(event, npc.conditionImmunities);
        this.syncNpc(npc);
    };

    hasSkill = skill => inList(this.state.npc.skills, skill);
    hasResistance = type => inList(this.state.npc.resistances, type);
    hasImmunity = type => inList(this.state.npc.immunities, type);
    hasVulnerability = type => inList(this.state.npc.vulnerabilities, type);
    hasConditionImmunity = type => inList(this.state.npc.conditionImmunities, type);
    hasLanguage = language => inList(this.state.npc.languages, language);

    updateSpeed = speeds => {
        const {npc} = this.state;
        npc.speeds = {...speeds};
        this.syncNpc(npc);
    };
    updateSenses = senses => {
        const {npc} = this.state;
        npc.senses = {...senses};
        this.syncNpc(npc);
    };
    updateLanguages = event => {
        const {npc} = this.state;
        npc.languages = addValueToList(event, npc.languages);
        this.syncNpc(npc);
    };


    reset = () => this.setState(this.getInitState());


    getInitState = () => {
        const reset = JSON.parse(JSON.stringify(this.initState));
        reset.id = createRandomString();

        return reset;
    };

    createNpcCopy = (npc) => ({
            ...npc,
            name: `${npc.name} copy`,
            id: createRandomString(),
            actions: npc.actions ? [...npc.actions] : [],
            special_abilities: npc.special_abilities ? [...npc.special_abilities] : [],
            legendary_actions: npc.legendary_actions ? [...npc.legendary_actions] : [],
            spells: npc.spells ? [...npc.spells] : [],
    });

    syncNpc = npc => {
        this.setState({npc: {...npc}}, () => this.props.syncNpc(npc));
    }

    foundryExport = () => {
        const {npc} = this.state;

        const json = exportNpcToFoundry(npc);
        copyToClipboard(JSON.stringify(json));
        this.props.showMessage('Foundry JSON copied to clipboard');
    };

    componentDidUpdate(prevProps, prevState) {
        const {npc, npcMode} = this.props;

        if (npc !== prevProps.npc || npcMode !== prevProps.npcMode) {
            if (Object.keys(npc).length === 0) {
                // If npc in store was cleared
                this.reset();
            } else if (npc.id !== this.state.npc.id) {
                // If a different NPC is being loaded
                const newNpc = Npc.checkBackwardsCompatibility(npc)
                this.setState({npc: {...newNpc}});
            } else {
                this.setState({npc: {...npc}});
            }
        }
    }

    componentDidMount() {
        const {npc} = this.props;

        if (Object.keys(npc).length === 0) {
            this.reset();
        } else {
            this.setState({npc: {...npc}});
        }
    }

    render() {
        const {npc, popover} = this.state;

        return (
            <form className="">
                <div className="col-12 bg-dark text-white p-2 pb-3 rounded shadow">
                    <MonsterSearch onSelect={() => this.setState({dirty: false})} />
                    <div className="form-row">
                        <div className="form-group col-6">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" name="name" id="name" required={true} aria-describedby="" placeholder="Enter name" value={npc ? npc.name : ''} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-2">
                            <label htmlFor="armorclass">AC</label>
                            <input type="number" className="form-control" name="ac" id="armorclass" required={true} aria-describedby="" placeholder="AC" value={npc && npc.ac} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-2">
                            <label htmlFor="hitpoints">HP</label>
                            <input type="number" className="form-control" name="hp" id="hitpoints" required={true} aria-describedby="" placeholder="HP" value={npc && npc.hp} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-2">
                            <label htmlFor="challenge_rating">CR</label>
                            <input type="text" className="form-control" name="challenge_rating" id="challenge_rating" aria-describedby="" placeholder="CR" value={npc && npc.challenge_rating} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-12">
                            <label htmlFor="npc-description">Description</label>
                            <textarea className="form-control" name="content" id="npc-description" rows="5" value={npc && npc.content} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-12">
                            <label htmlFor="url">Url</label>
                            <input type="text" className="form-control" name="url" id="url" aria-describedby="" placeholder="Enter content url" value={npc && npc.url} onChange={this.handleInputChange} />
                        </div>
                    </div>

                    {(!npc || !npc.id) && <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => {
                        const {npc} = this.state;
                        this.props.addNpc({...npc, id: createRandomString()});
                        this.props.showMessage(`${npc.name} added`);
                        this.reset();
                    }}><i className="material-icons">face</i> Add</button>}
                    {npc && npc.id && <div className="d-flex justify-content-between">
                        <div className="d-inline-block">
                            <button type="button" className="btn btn-info pl-5 position-relative" onClick={() => {
                                this.props.updateNpc(npc);
                            }}>
                                <i className="material-icons">face</i> Update
                            </button>
                            <button type="button" className="btn btn-secondary ml-2 pl-5 position-relative" onClick={() => {
                                this.props.addNpc(this.createNpcCopy(npc));
                            }}>
                                <i className="material-icons">file_copy</i> Create Copy
                            </button>
                            <button className="btn btn-danger pl-5 ml-2 position-relative" onClick={this.props.clearNpc}>
                                <i className="material-icons">autorenew</i>Clear
                            </button>
                        </div>
                        <div className="d-inline-block">
                            <button type="button" className="btn btn-info pl-5 ml-2 position-relative" onClick={this.foundryExport}>
                                <i className="material-icons"><Icon path={mdiDiceD20Outline} size={1} /></i>Foundry
                            </button>
                        </div>
                    </div>}
                </div>

                <div className="col-12 bg-dark text-white p-2 pb-2 mt-4 rounded shadow">
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label htmlFor="strength">Strength</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input className="position-static" type="checkbox" name="strProficient" checked={npc && npc.strProficient === true} onChange={this.handleInputChange} title="Proficient" />
                                    </div>
                                </div>
                                <input type="number" className="form-control" name="strength" id="strength" aria-describedby="" placeholder="Strength" value={npc ? npc.strength : ''} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="dexterity">Dexterity</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input className="position-static" type="checkbox" name="dexProficient" checked={npc && npc.dexProficient === true} onChange={this.handleInputChange} title="Proficient" />
                                    </div>
                                </div>
                                <input type="number" className="form-control" name="dexterity" id="dexterity" aria-describedby="" placeholder="Dexterity" value={npc && npc.dexterity} onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="constitution">Constitution</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input className="position-static" type="checkbox" name="conProficient" checked={npc && npc.conProficient === true} onChange={this.handleInputChange} title="Proficient" />
                                    </div>
                                </div>
                                <input type="number" className="form-control" name="constitution" id="constitution" aria-describedby="" placeholder="Constitution" value={npc && npc.constitution} onChange={this.handleInputChange} />
                            </div>

                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label htmlFor="intelligence">Intelligence</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input className="position-static" type="checkbox" name="intProficient" checked={npc && npc.intProficient === true} onChange={this.handleInputChange} title="Proficient" />
                                    </div>
                                </div>
                                <input type="number" className="form-control" name="intelligence" id="intelligence" aria-describedby="" placeholder="Intelligence" value={npc ? npc.intelligence : ''} onChange={this.handleInputChange} />
                            </div>

                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="wisdom">Wisdom</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input className="position-static" type="checkbox" name="wisProficient" checked={npc && npc.wisProficient === true} onChange={this.handleInputChange} title="Proficient" />
                                    </div>
                                </div>
                                <input type="number" className="form-control" name="wisdom" id="wisdom" aria-describedby="" placeholder="Wisdom" value={npc && npc.wisdom} onChange={this.handleInputChange} />
                            </div>

                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="charisma">Charisma</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <input className="position-static" type="checkbox" name="chaProficient" checked={npc && npc.chaProficient === true} onChange={this.handleInputChange} title="Proficient" />
                                    </div>
                                </div>
                                <input type="number" className="form-control" name="charisma" id="charisma" aria-describedby="" placeholder="Charisma" value={npc && npc.charisma} onChange={this.handleInputChange} />
                            </div>

                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-6">
                            <label htmlFor="skill_proficiencies">Skill Proficiencies</label>
                            <input type="text" className="form-control" name="skill_proficiencies" id="skill_proficiencies" placeholder="Skill Proficiencies" value={npc.skills ? getProficiencies(npc.skills) : ''} onClick={() => this.setState({'popover': 'skillproficiencies'})} readOnly={true} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="spellcasting_ability">Spellcasting Ability</label>
                            <select className="form-control" id="spellcasting_ability" name="spellcasting_ability" onChange={this.handleInputChange} value={npc && npc.spellcasting_ability}>
                                <option value="" />
                                <option value="str">Strength</option>
                                <option value="dex">Dexterity</option>
                                <option value="con">Constitution</option>
                                <option value="int">Intelligence</option>
                                <option value="wis">Wisdom</option>
                                <option value="cha">Charisma</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-12 bg-dark text-white p-2 pb-2 mt-4 rounded shadow">
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label htmlFor="size">Size</label>
                            <select className="form-control" id="size" name="size" onChange={this.handleInputChange} value={npc ? npc.size.toLowerCase() : 'medium'}>
                                <option value="gargantuan">Gargantuan</option>
                                <option value="huge">Huge</option>
                                <option value="large">Large</option>
                                <option value="medium">Medium</option>
                                <option value="small">Small</option>
                                <option value="tiny">Tiny</option>
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="type">Type</label>
                            <select className="form-control" name="type" id="type" value={npc ? npc.type : 'humanoid'} onChange={this.handleInputChange}>
                                {NPC_TYPES.map(type => <option value={type.key} key={`type-option-${type.key}`}>{type.name}</option>)}
                            </select>
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="alignment">Alignment</label>
                            <select className="form-control" name="alignment" id="alignment" value={npc ? npc.alignment : 'neutral'} onChange={this.handleInputChange}>
                                <option value="unaligned">Unaligned</option>
                                <option value="lawful evil">Lawful Evil</option>
                                <option value="neutral evil">Neutral Evil</option>
                                <option value="chaotic evil">Chaotic Evil</option>
                                <option value="lawful neutral">Lawful Neutral</option>
                                <option value="neutral">Neutral</option>
                                <option value="chaotic neutral">Chaotic Neutral</option>
                                <option value="lawful good">Lawful Good</option>
                                <option value="neutral good">Neutral Good</option>
                                <option value="chaotic good">Chaotic Good</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label htmlFor="speed">Speed</label>
                            <input type="text" className="form-control" name="speed" id="speed" placeholder="Speed" value={getDistances(npc.speeds)} onClick={() => this.setState({'popover': 'speed'})} readOnly={true} />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="languages">Languages</label>
                            <input type="text" className="form-control" name="languages" id="languages" placeholder="Languages" value={npc.languages ? npc.languages.join(', ') : ''} onClick={() => this.setState({'popover': 'languages'})} readOnly={true} />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="senses">Senses</label>
                            <input type="text" className="form-control" name="senses" id="senses" placeholder="Senses" value={getDistances(npc.senses)} onClick={() => this.setState({'popover': 'senses'})} readOnly={true} />
                        </div>
                    </div>
                </div>

                <div className="col-12 bg-dark text-white p-2 pb-2 mt-4 rounded shadow">
                    <div className="form-row">
                        <div className="form-group col-6">
                            <label htmlFor="damage_vulnerabilities">Vulnerabilities</label>
                            <input type="text" className="form-control" name="damage_vulnerabilities" id="damage_vulnerabilities" placeholder="Damage Vulnerabilities" value={npc.vulnerabilities ? npc.vulnerabilities.join(', ') : ''} onClick={() => this.setState({'popover': 'vulnerabilities'})} readOnly={true} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="damage_resistances">Resistances</label>
                            <input type="text" className="form-control" name="damage_resistances" id="damage_resistances" placeholder="Damage Resistances" value={npc.resistances ? npc.resistances.join(', ') : ''} onClick={() => this.setState({'popover': 'resistances'})} readOnly={true} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-6">
                            <label htmlFor="damage_immunities">Damage Immunities</label>
                            <input type="text" className="form-control" name="damage_immunities" id="damage_immunities" placeholder="Damage Immunities" value={npc.immunities ? npc.immunities.join(', ') : ''} onClick={() => this.setState({'popover': 'immunities'})} readOnly={true} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="condition_immunities">Condition Immunities</label>
                            <input type="text" className="form-control" name="condition_immunities" id="condition_immunities" placeholder="Condition Immunities" value={npc.conditionImmunities ? npc.conditionImmunities.join(', ') : ''} onClick={() => this.setState({'popover': 'conditions'})} readOnly={true} />
                        </div>
                    </div>
                </div>

                <NpcSkills npc={npc} />

                {popover === 'resistances' && <Popover title="Resistances" close={() => this.setState({popover: ''})}>
                    <DamageTypeList hasType={this.hasResistance} changeType={this.changeResistance} />
                </Popover>}

                {popover === 'vulnerabilities' && <Popover title="Vulnerabilities" close={() => this.setState({popover: ''})}>
                    <DamageTypeList hasType={this.hasVulnerability} changeType={this.changeVulnerability} />
                </Popover>}

                {popover === 'immunities' && <Popover title="Immunities" close={() => this.setState({popover: ''})}>
                    <DamageTypeList hasType={this.hasImmunity} changeType={this.changeImmunity} />
                </Popover>}

                {popover === 'conditions' && <Popover title="Immunities" close={() => this.setState({popover: ''})}>
                    <ConditionTypeList hasType={this.hasConditionImmunity} changeType={this.changeConditionImmunity} />
                </Popover>}

                {popover === 'speed' && <Popover title="Speed" close={() => this.setState({popover: ''})}>
                    <SpeedForm update={this.updateSpeed} speed={npc.speeds} />
                </Popover>}

                {popover === 'senses' && <Popover title="Senses" close={() => this.setState({popover: ''})}>
                    <SensesForm update={this.updateSenses} senses={npc.senses} />
                </Popover>}

                {popover === 'languages' && <Popover title="Languages" close={() => this.setState({popover: ''})}>
                    <LanguageList update={this.updateLanguages} languages={npc.languages} hasLanguage={this.hasLanguage} />
                </Popover>}

                {popover === 'skillproficiencies' && <Popover title="Proficiencies" close={() => this.setState({popover: ''})}>
                    <SkillProficiencyList hasSkill={this.hasSkill} changeSkill={this.changeSkill} />
                </Popover>}


            </form>
        );
    }
}

const mapStateToProps = state => {
    const {npc, npcMode} = state;

    return {npc, npcMode};
};

const mapDispatchToProps = dispatch => ({
    addNpc: npc => dispatch(addNpc(npc)),
    updateNpc: npc => dispatch(updateNpc(npc)),
    clearNpc: () => dispatch(clearNpc()),
    syncNpc: npc => dispatch(editNpc(npc)),
    showMessage: message => dispatch(showMessage(message)),
});


export default connect(mapStateToProps, mapDispatchToProps)(NpcForm);