import React from 'react';

class SpeedForm extends React.Component {

    constructor(props) {
        super(props);

        const {
            walking = 30,
            flying = 0,
            burrowing = 0,
            climbing = 0,
            swimming = 0,
        } = props.speed;

        this.state = {
            speed: {
                walking,
                flying,
                burrowing,
                climbing,
                swimming,
            }
        }
    }

    handleInputChange = (event) => {
        const {target: {name, value}} = event;
        const {speed} = this.state;

        speed[name] = value;

        this.setState({speed: {...speed}}, this.props.update(speed));
    };

    render() {
        const {walking, flying, burrowing, climbing, swimming} = this.state.speed;

        return <div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="walking">Walking</label>
                    <input type="number" className="form-control" name="walking" id="walking" placeholder="Walking Speed" value={walking} onChange={this.handleInputChange} />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="flying">Flying</label>
                    <input type="number" className="form-control" name="flying" id="flying" placeholder="Flying Speed" value={flying} onChange={this.handleInputChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="burrowing">Burrowing</label>
                    <input type="number" className="form-control" name="burrowing" id="burrowing" placeholder="Burrowing Speed" value={burrowing} onChange={this.handleInputChange} />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="climbing">Climbing</label>
                    <input type="number" className="form-control" name="climbing" id="climbing" placeholder="Climbing Speed" value={climbing} onChange={this.handleInputChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="swimming">Swimming</label>
                    <input type="number" className="form-control" name="swimming" id="swimming" placeholder="Swimming Speed" value={swimming} onChange={this.handleInputChange} />
                </div>
            </div>
        </div>;
    }
}

export default SpeedForm;