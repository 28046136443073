import {
    SWITCH_CONTEXT,
    ADD_CONTENDER,
    REMOVE_CONTENDER,
    UPDATE_CONTENDER,
    RESET_CONTENDERS,
    ADD_PLAYER,
    REMOVE_PLAYER,
    EDIT_PLAYER,
    UPDATE_PLAYER,
    ADD_NPC,
    REMOVE_NPC,
    EDIT_NPC,
    UPDATE_NPC,
    SET_MESSAGE,
    VIEW_NPC,
    CLEAR_NPC,
    NAVIGATE_TO_NPC,
    SHOW_NPC_SKILL_FORM,
    ADD_NPC_SKILL,
    REMOVE_NPC_SKILL,
    SWITCH_NPC_MODE,
    IMPORT_STORE,
    EDIT_NPC_SPELL, EDIT_NPC_SKILL, UPDATE_NPC_SPELL, UPDATE_NPC_SKILL,
} from './actionTypes';
import {sortContenders} from "./helpers";
import Npc from '../models/npc';

export const initialNpc = {
    id: null,
    name: '',
    ac: '',
    hp: '',
    attackRoll: '',
    damageRoll: '',
    content: '',
    url: '',
    type: 'humanoid',
    subtype: '',
    alignment: 'neutral',
    senses: {},
    languages: ['common'],
    strength: '',
    dexterity: '',
    constitution: '',
    intelligence: '',
    wisdom: '',
    charisma: '',
    strProficient: false,
    dexProficient: false,
    conProficient: false,
    intProficient: false,
    wisProficient: false,
    chaProficient: false,
    size: 'medium',
    damage_vulnerabilities: '',
    damage_resistances: '',
    damage_immunities: '',
    condition_immunities: '',
    challenge_rating: '',
    actions: [],
    special_abilities: [],
    legendary_actions: [],
    spells: [],
    spellcasting_ability: '',
    skills: [],
    resistances: [],
    immunities: [],
    vulnerabilities: [],
    conditionImmunities: [],
    speeds: {walking: 30},
};

export const initialState = {
    message:{
        message: '',
        timestamp: null,
        type: '',
    },
    context: 'initiative',
    contenders: [],
    party: [],
    npcs: [],
    skillType: '',
    editSkillIndex: null,
    skill: {},
    spell: {},
    player: {},
    npc: {...initialNpc},
    npcMode: 'edit',
    dirtyNpc: false,
    popover: '',
};

function initiative(state = initialState, action) {
    switch (action.type) {
        case SWITCH_CONTEXT: {
            return {
                ...state,
                context: action.payload.context,
            }
        }
        case SET_MESSAGE: {
            const {message, timestamp, type} = action.payload;

            return {
                ...state,
                message: {
                    message, timestamp, type,
                }
            }
        }
        case ADD_CONTENDER: {
            const contenders = [...state.contenders];

            contenders.push(action.payload.contender);

            return {
                ...state,
                contenders: sortContenders(contenders),
            };
        }
        case REMOVE_CONTENDER: {
            const contenders = [...state.contenders];

            contenders.splice(action.payload.index, 1);

            return {
                ...state,
                contenders: contenders,
            };
        }
        case UPDATE_CONTENDER: {
            let contenders = [...state.contenders];
            const {index, name, value} = action.payload;

            contenders[index][name] = value;

            if (name === 'initiative') {
                contenders = sortContenders(contenders);
            }

            return {
                ...state,
                contenders: contenders,
            };
        }
        case RESET_CONTENDERS: {
            return {
                ...state,
                contenders: [],
            };
        }
        case ADD_PLAYER: {
            const party = [...state.party];
            const {player} = action.payload;

            party.push(player);

            return {
                ...state,
                party,
            };
        }
        case REMOVE_PLAYER: {
            const party = [...state.party];
            const {index} = action.payload;

            party.splice(index, 1);

            return {
                ...state,
                party,
            }
        }
        case EDIT_PLAYER: {
            const {player} = action.payload;

            return {...state, player};
        }
        case UPDATE_PLAYER: {
            let party = [...state.party];
            const {player} = action.payload;
            let playerIndex = null;

            party.forEach((partyMember, index) => {
                if (player.id === partyMember.id) {
                    playerIndex = index;
                }
            });
            party.splice(playerIndex, 1, player);

            return {...state, party, player: {}};
        }
        case ADD_NPC: {
            const npcs = [...state.npcs];
            const {npc} = action.payload;

            npcs.push(npc);

            return {
                ...state,
                npc: {...initialNpc},
                npcs,
            }
        }
        case REMOVE_NPC: {
            const npcs = [...state.npcs];
            const {index} = action.payload;

            npcs.splice(index, 1);

            return {
                ...state,
                npc: {...initialNpc},
                npcs,
            }
        }
        case EDIT_NPC: {
            const {npc} = action.payload;

            return {...state, npc: {...npc}, npcMode: 'edit', editSkillIndex: null, skillType: ''};
        }
        case VIEW_NPC: {
            const {npc} = action.payload;

            return {...state, npc: Npc.checkBackwardsCompatibility(npc), npcMode: 'view'};
        }
        case CLEAR_NPC: {
            return {...state, npc: {...initialNpc}, npcMode: 'edit'};
        }
        case UPDATE_NPC: {
            let npcs = [...state.npcs];
            const {npc} = action.payload;
            let npcIndex = findNpcIndex(npc.id, npcs);

            npcs.splice(npcIndex, 1, npc);

            return {...state, npcs: [...npcs], npc: {}};
        }
        case NAVIGATE_TO_NPC: {
            const {npc} = action.payload;

            return {...state, npc, npcMode: 'view', context: 'npcs'};
        }
        case SWITCH_NPC_MODE: {
            const {npcMode} = action.payload;

            return {...state, npcMode};
        }
        case SHOW_NPC_SKILL_FORM: {
            const {type} = action.payload;

            return {...state, skillType: type, editSkillIndex: null}
        }
        case ADD_NPC_SKILL: {
            const {npc, skill} = action.payload;
            const {npcs} = state;

            if (!npc[skill.type]) {
                npc[skill.type] = [];
            }

            npc[skill.type].push(skill);
            npc[skill.type] = [...npc[skill.type]];

            return {...state, npc: {...npc}, skillType: '', npcs: getUpdatedNpcList(npc, npcs)}
        }
        case REMOVE_NPC_SKILL: {
            const {type, index} = action.payload;
            const {npc, npcs} = state;

            const skills = [...npc[type]];

            skills.splice(index, 1);
            npc[type] = [...skills];

            return {...state, npc: {...npc}, npcs: getUpdatedNpcList(npc, npcs)}
        }
        case EDIT_NPC_SKILL: {
            const {type, index} = action.payload;

            return {...state, skillType: type, editSkillIndex: index};
        }
        case UPDATE_NPC_SKILL: {
            const {skill, type, index} = action.payload;
            const {npc, npcs} = state;

            npc[type].splice(index, 1, skill);

            return {
                ...state,
                npc: {...npc},
                skillType: '',
                editSkillIndex: null,
                npcs: getUpdatedNpcList(npc, npcs),
            };
        }
        case IMPORT_STORE: {
            const {party, npcs} = action.payload;

            return {
                ...state,
                party,
                npcs,
                context: 'initiative',
                skill: {},
                skillType: '',
                player: {},
                npc: {},
                npcMode: 'edit',
            }
        }
        case EDIT_NPC_SPELL: {
            const {spell} = action.payload;

            return {
                ...state,
                spell: {...spell},
            };
        }
        case UPDATE_NPC_SPELL: {
            const {spell, npc, index} = action.payload;
            const {npcs} = state;

            npc.spells.splice(index, 1, spell);

            return {
                ...state,
                npc: {...npc},
                skillType: '',
                editSkillIndex: null,
                npcs: getUpdatedNpcList(npc, npcs),
            };
        }
        default: {
            return state;
        }
    }
}

function findNpcIndex(id, npcs) {
    let npcIndex = null;

    npcs.forEach((npcItem, index) => {
        if (id === npcItem.id) {
            npcIndex = index;
        }
    });

    return npcIndex;
}

function getUpdatedNpcList(npc, npcs) {
    const npcIndex = findNpcIndex(npc.id, npcs);

    // When we're editing an npc, update their skills and spells
    if (npcIndex !== null) {
        const {actions, special_abilities, legendary_actions, spells} = npc;

        npcs[npcIndex].actions = actions;
        npcs[npcIndex].special_abilities = special_abilities;
        npcs[npcIndex].legendary_actions = legendary_actions;
        npcs[npcIndex].spells = spells;
    }

    return npcs;
}

export default initiative;