import React from 'react';
import Spell from './spell';

const spells = require('../dataset/spells');

/**
 * Source spells dataset: https://github.com/vorpalhex/srd_spells
 */
class Spells extends React.Component {

    constructor(props) {
        super(props);

        this.initState = {
            query: '',
            results: [],
        };

        this.state = this.initState;
    }

    search = (event) => {
        const {value} = event.target;

        const results = spells.filter((item) => {
            return value !== '' && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        });

        this.setState({
            query: value,
            results,
        });
    };

    renderResults = (results) => {
        if (results.length === 0) {
            return <div className="card text-white bg-dark mb-3 p-2">
                No results
            </div>
        }

        return results.map((item, index) => {
            return <Spell key={`spell-item-${index}`} spell={item} />
        });
    };

    render() {
        const {query, results} = this.state;

        return (
            <div className="row">
                <div className="col-lg-4 mt-3 pr-0">
                    <div className="form-row position-relative">
                        <div className="form-group col-12 border border-secondary p-2 bg-white rounded">
                            <label htmlFor="url">Search</label>
                            <input type="text" className="form-control" name="spell-search" id="spell-search" aria-describedby="" placeholder="Search by spell name..." value={query} onChange={this.search} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-12 mt-3 mt-lg-3 pr-0 pl-2 pl-lg-3">
                    {this.renderResults(results)}
                </div>
            </div>
        );
    }
}

export default Spells;