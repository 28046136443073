import {
    SWITCH_CONTEXT,
    ADD_CONTENDER,
    REMOVE_CONTENDER,
    UPDATE_CONTENDER,
    RESET_CONTENDERS,
    ADD_PLAYER,
    REMOVE_PLAYER,
    EDIT_PLAYER,
    UPDATE_PLAYER,
    ADD_NPC,
    REMOVE_NPC,
    EDIT_NPC,
    UPDATE_NPC,
    SET_MESSAGE,
    VIEW_NPC,
    CLEAR_NPC,
    NAVIGATE_TO_NPC,
    ADD_NPC_SKILL,
    SHOW_NPC_SKILL_FORM,
    REMOVE_NPC_SKILL,
    SWITCH_NPC_MODE,
    IMPORT_STORE,
    EDIT_NPC_SPELL,
    SET_NPC_DIRTY,
    EDIT_NPC_SKILL,
    UPDATE_NPC_SPELL, UPDATE_NPC_SKILL
} from './actionTypes';

export const switchContext = (context) => ({
    type: SWITCH_CONTEXT,
    payload: {context},
});

export const showMessage = (message, type= 'success') => ({
    type: SET_MESSAGE,
    payload: {message, timestamp: Date.now(), type},
});

export const addContender = (contender) => ({
    type: ADD_CONTENDER,
    payload: {contender},
});

export const removeContender = (index) => ({
    type: REMOVE_CONTENDER,
    payload: {index},
});

export const updateContender = (index, name, value) => ({
    type: UPDATE_CONTENDER,
    payload: {
        index,
        name,
        value,
    }
});

export const resetContenders = (index, name, value) => ({
    type: RESET_CONTENDERS,
});

export const addPlayer = (player) => ({
    type: ADD_PLAYER,
    payload: {player}
});

export const removePlayer = (index) => ({
    type: REMOVE_PLAYER,
    payload: {index}
});

export const editPlayer = (player) => ({
    type: EDIT_PLAYER,
    payload: {player}
});

export const updatePlayer = (player, party) => ({
    type: UPDATE_PLAYER,
    payload: {player, party}
});

export const addNpc = (npc) => ({
    type: ADD_NPC,
    payload: {npc}
});

export const removeNpc = (index) => ({
    type: REMOVE_NPC,
    payload: {index}
});

export const editNpc = (npc) => ({
    type: EDIT_NPC,
    payload: {npc}
});

export const updateNpc = (npc) => ({
    type: UPDATE_NPC,
    payload: {npc}
});

export const viewNpc = (npc) => ({
    type: VIEW_NPC,
    payload: {npc}
});

export const clearNpc = () => ({
    type: CLEAR_NPC,
});

export const navigateToNpc = (npc) => ({
    type: NAVIGATE_TO_NPC,
    payload: {npc}
});

export const showNpcSkillForm = (type) => ({
    type: SHOW_NPC_SKILL_FORM,
    payload: {type},
});

export const addSkillToCurrentNpc = (npc, skill) => ({
    type: ADD_NPC_SKILL,
    payload: {npc, skill},
});

export const removeSkill = (type, index) => ({
    type: REMOVE_NPC_SKILL,
    payload: {type, index},
});

export const editSkill = (type, index) => ({
    type: EDIT_NPC_SKILL,
    payload: {type, index},
});

export const setNpcDirty = (dirty) => ({
    type: SET_NPC_DIRTY,
    payload: {dirty},
});

export const switchNpcMode = (npcMode) => ({
    type: SWITCH_NPC_MODE,
    payload: {npcMode},
});

export const importStore = (party, npcs) => ({
    type: IMPORT_STORE,
    payload: {party, npcs},
});

export const editNpcSpell = (spell) => ({
    type: EDIT_NPC_SPELL,
    payload: {spell}
});

export const updateNpcSpell = (npc, spell, index) => ({
    type: UPDATE_NPC_SPELL,
    payload: {npc, spell, index}
});

export const updateNpcSkill = (skill, type, index) => ({
    type: UPDATE_NPC_SKILL,
    payload: {skill, type, index}
});