import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import initiative from './store/reducer';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {loadState, saveState} from './store/localstorage';

const middleware = applyMiddleware(thunkMiddleware);
const composed = compose(middleware);
const store = createStore(initiative, loadState(), composed);

store.subscribe(() => {
    saveState(store.getState());
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
