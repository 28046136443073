import Model from './model';

class Player extends Model {
    constructor(
        name,
        ac,
        hp,
        race,
        level,
        background,
        perception,
        investigation,
        insight,
    ) {
        super();
        this.id = this.createRandomString(16);
        this.name = name;
        this.ac = ac;
        this.hp = hp;
        this.race = race;
        this.level = level;
        this.background = background;
        this.perception = perception;
        this.investigation = investigation;
        this.insight = insight;
    }

    update(player) {
        this.name = player.name;
        this.ac = player.ac;
        this.hp = player.hp;
        this.race = player.race;
        this.level = player.level;
        this.background = player.background;
        this.perception = player.perception;
        this.investigation = player.investigation;
        this.insight = player.insight;        
    }

    toObject() {
        return {
            id: this.id,
            name: this.name,
            ac: this.ac,
            hp: this.hp,
            race: this.race,
            level: this.level,
            background: this.background,
            perception: this.perception,
            investigation: this.investigation,
            insight: this.insight,
        };
    };

    static fromObject(object) {
        return new Player(
            object.name,
            object.ac,
            object.hp,
            object.race,
            object.level,
            object.background,
            object.perception,
            object.investigation,
            object.insight,
        );
    }
}

export default Player;