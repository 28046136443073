import React from 'react';
import {connect} from 'react-redux';
import {addContender, removePlayer, editPlayer, showMessage} from '../../store/actions';
import Contender from '../../models/contender';

class PlayerList extends React.Component {

    addPlayerAsContender = (player) => {
        this.props.showMessage(`${player.name} was added to encounter`);
        this.props.addContender(new Contender(player.name, player.ac, player.hp));
    };

    editPlayer = (index) => {
        const player = this.props.party[index];

        this.props.editPlayer(player);
    };

    render() {
        const {party} = this.props;
        
        return (
            <div className="col-12 border border-secondary p-2 rounded bg-white shadow">
                <table className="table table-striped table-responsive-lg">
                    <thead>
                        <tr>
                            <th className="border-top-0">Name</th>
                            <th className="border-top-0 position-relative">AC</th>
                            <th className="border-top-0 position-relative">Max HP</th>
                            <th className="border-top-0">Level</th>
                            <th className="border-top-0">Race</th>
                            <th className="border-top-0">Background</th>
                            <th className="border-top-0">Perception</th>
                            <th className="border-top-0">Investigation</th>
                            <th className="border-top-0">Insight</th>
                            <th className="th-player-btns border-top-0"><span /></th>
                        </tr>                    
                    </thead>
                    <tbody>
                        {party.map((player, index) => {
                            return (
                                <tr key={player.id}>
                                    <td>{player.name}</td>
                                    <td>{player.ac}</td>
                                    <td>{player.hp}</td>
                                    <td>{player.level}</td>
                                    <td>{player.race}</td>
                                    <td>{player.background}</td>
                                    <td>{player.perception}</td>
                                    <td>{player.investigation}</td>
                                    <td>{player.insight}</td>
                                    <td>
                                        <button className="btn table-btn text-primary" onClick={() => this.editPlayer(index)}>
                                            <i className="material-icons md-light">create</i>
                                        </button>
                                        <button className="btn table-btn text-success" type="button" onClick={() => this.addPlayerAsContender(player)}>
                                            <i className="material-icons md-light">play_circle_outline</i>
                                        </button>
                                        <button className="btn table-btn text-danger" type="button" onClick={() => window.confirm(`Remove ${player.name} from party?`) && this.props.removePlayer(index)}>
                                            <i className="material-icons md-light">close</i>
                                        </button>                                        
                                    </td>
                                </tr>
                            );                
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {party} = state;

    return {party};
};

const mapDispatchToProps = dispatch => ({
    removePlayer: index => dispatch(removePlayer(index)),
    addContender: (contender) => dispatch(addContender(contender)),
    editPlayer: player => dispatch(editPlayer(player)),
    showMessage: message => dispatch(showMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerList);