export function calculateProficiencyFromChallengeRating(challengeRating) {
    return challengeRating ? 2 + Math.floor(Math.abs(parseInt(challengeRating)-1)/4) : 2;
}

export function calculateAbilityModifier(score) {
    return score ? Math.floor((score-10)/2) : 0;
}

export function calcSpellDC(npc) {
    const {challenge_rating} = npc;
    const proficiency = calculateProficiencyFromChallengeRating(challenge_rating);
    const modifier = parseInt(getSpellcastingModifer(npc));

    return 8 + proficiency + modifier;
}

export function getSpellcastingModifer(npc) {
    const {spellcasting_ability} = npc;

    if (spellcasting_ability) {
        return npc[spellcasting_ability] ? calculateAbilityModifier(npc[spellcasting_ability]) : '0';
    }

    return 0;
}