import React from 'react';
import {connect} from 'react-redux';
import {addSkillToCurrentNpc, showMessage, updateNpcSkill} from '../../store/actions';
import SkillDamageRolls from './skillDamageRolls';
import SkillRange from './skillRange';
import SpellActivation from './spellActivation';
import {calculateAbilityModifier, calculateProficiencyFromChallengeRating} from '../helpers/npc';

class NpcSkillForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            skill: this.getInitialSkill(props.type),
        };
    }

    componentDidMount() {
        const {editIndex} = this.props;

        if (editIndex !== null) {
            this.populateForm();
        } else {
            this.initSkill();
        }
    }

    componentDidUpdate(prevProps) {
        const {editIndex} = this.props;

        if (editIndex !== null && prevProps.editIndex !== editIndex) {
            this.populateForm();
        } else if (editIndex === null && prevProps.editIndex !== editIndex) {
            this.initSkill();
        }
    }

    initSkill = () => {
        const {type} = this.props;

        this.setState({skill: this.getInitialSkill(type)});
    };

    getInitialSkill = type => {
        return {
            type,
            name: '',
            desc: '',
            damage_parts: [],
            range: {},
            target: {},
            activation: {},
            attack_bonus: this.calculateDefaultAttackBonus(),
        }
    };

    populateForm = () => {
        const {editIndex, type, npc} = this.props;
        const skill = npc[type][editIndex];

        if (skill) {
            const {name, desc, damage_parts = [], range, target, activation, attack_bonus} = skill;

            this.setState({skill: {type, name, desc, damage_parts, range, target, activation, attack_bonus}});
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const {skill} = this.state;

        skill[name] = target.value;
        this.setState({skill: {...skill}});
    };

    addSkillToNpc = (npc, skill) => {
        this.props.addSkillToCurrentNpc(npc, skill);
        this.props.showMessage(`${skill.name} added to NPC`);
    };

    onChangeRange = range => {
        const {skill} = this.state;

        skill.range = range;
        this.setState({skill: {...skill}});
    };

    onChangeTarget = target => {
        const {skill} = this.state;

        skill.target = target;
        this.setState({skill: {...skill}});
    };

    updateSkill = (type, index) => {
        const {skill} = this.state;

        this.props.updateNpcSkill(skill, type, index);
    };

    updateDamageParts = parts => {
        const {skill} = this.state;
        skill.damage_parts = parts.map(part => [
            part.bonus ? `${part.rolls} + ${part.bonus}` : `${part.rolls}`,
            part.type,
        ]);

        this.setState({skill: {...skill}});
    };

    updateActivation = activation => {
        const {skill} = this.state;
        skill.activation = activation;
        this.setState({skill: {...skill}});
    };

    calculateDefaultAttackBonus = () => {
        const {npc: {challenge_rating, strength}} = this.props;

        return calculateProficiencyFromChallengeRating(challenge_rating) + calculateAbilityModifier(strength);
    };

    render() {
        const {npc, editIndex} = this.props;
        const {skill} = this.state;
        const {type, damage_parts = [], target, range, activation} = skill;

        return <div id="skill-form" className="col-12 bg-dark text-white p-2 pb-3 rounded shadow mt-4">
            <div className="form-row">
                <div className="form-group col-12">
                    <label htmlFor="type">Skill Type</label>
                    <select className="form-control" id="type" name="type" onChange={this.handleInputChange} defaultValue={type}>
                        <option value="actions">Action</option>
                        <option value="special_abilities">Special Abilities</option>
                        <option value="legendary_actions">Legendary Abilities</option>
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-9">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" name="name" id="name" aria-describedby="" placeholder="Name" value={skill ? skill.name : ''} onChange={this.handleInputChange} />
                </div>
                <div className="form-group col-3">
                    <label htmlFor="attackBonus">Attack Bonus</label>
                    <input type="text" className="form-control" name="attack_bonus" id="attackBonus" aria-describedby="" placeholder="Attack Bonus" value={skill.attack_bonus} onChange={this.handleInputChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-12">
                    <label htmlFor="description">Description</label>
                    <textarea className="form-control" name="desc" id="description" rows="5" value={skill && skill.desc} onChange={this.handleInputChange} />
                </div>
            </div>
            <SpellActivation onActivationChange={this.updateActivation} cost={activation.cost} type={activation.type} allowEmpty={true} />
            <SkillRange index={editIndex} target={target} range={range} onChangeRange={this.onChangeRange} onChangeTarget={this.onChangeTarget} allowEmpty={true} />
            <SkillDamageRolls parts={damage_parts} onChange={this.updateDamageParts} />
            <span>
                {editIndex === null && <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => this.addSkillToNpc(npc, skill)}>
                    <i className="material-icons">add</i> Add Skill
                </button>}

                {editIndex !== null && <button type="button" className="btn btn-info pl-5 position-relative" onClick={() => this.updateSkill(type, editIndex)}>
                    <i className="material-icons">stars</i> Update Skill
                </button>}
            </span>

        </div>
    }
}

const mapDispatchToProps = dispatch => ({
    addSkillToCurrentNpc: (npc, skill) => dispatch(addSkillToCurrentNpc(npc, skill)),
    showMessage: message => dispatch(showMessage(message)),
    updateNpcSkill: (skill, type, index) => dispatch(updateNpcSkill(skill, type, index)),
});


export default connect(null, mapDispatchToProps)(NpcSkillForm);