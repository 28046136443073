import React from 'react';
import {connect} from 'react-redux';
import {addContender, editNpc, removeNpc, showMessage, viewNpc} from '../../store/actions';
import Contender from '../../models/contender';

class NpcList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            search: '',
        };
    }

    search = (event) => {
        const {value} = event.target;

        this.setState({search: value})
    };

    editNpc = (index) => {
        const npc = this.props.npcs[index];

        this.props.editNpc(npc);
    };

    viewNpc = (npc) => {
        this.props.viewNpc(npc);
    };

    addNpcAsContender = (npc) => {
        this.props.showMessage(`${npc.name} was added to encounter`);
        this.props.addContender(new Contender(npc.name, npc.ac, npc.hp, 'npc', npc.id, npc.dexterity));
    };

    render() {
        const {npcs} = this.props;
        const {search} = this.state;
        const selectedNpc = this.props.npc;

        return (
            <div className="col col-12 border border-secondary p-2 bg-white rounded">
                <div className="form-group pb-6">
                    <label htmlFor="search">Filter NPC</label>
                    <input type="text" className="form-control" id="search" aria-describedby="" placeholder="Filter by name" value={undefined} onChange={this.search} />
                </div>
                <ul className="list-group">
                    {npcs.map((npc, index) => {
                        if (npc.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                            return (
                                <li className={`npc-item list-group-item list-group-item-action d-flex flex-row justify-content-between cursor-pointer ${selectedNpc && npc.id === selectedNpc.id ? 'list-group-item-dark' : ''}`} key={npc.id} onClick={() => this.viewNpc(npc)}>
                                    <span>{npc.name}</span>
                                    <span>
                                        <button className="btn table-btn text-primary" onClick={(event) => {
                                            event.stopPropagation();
                                            this.editNpc(index);
                                        }}>
                                            <i className="material-icons md-light">create</i>
                                        </button>
                                        <button className="btn table-btn text-success" type="button" onClick={(event) => {
                                            event.stopPropagation();
                                            this.addNpcAsContender(npc);
                                        }}>
                                            <i className="material-icons md-light">play_circle_outline</i>
                                        </button>
                                        <button className="btn table-btn text-danger" type="button" onClick={(event) => {
                                            event.stopPropagation();
                                            window.confirm(`Remove ${npc.name} from list?`) && this.props.removeNpc(index);
                                        }}>
                                            <i className="material-icons md-light">close</i>
                                        </button>
                                    </span>
                                </li>
                            );
                        }

                        return null;
                    })}
                </ul>
            </div>
        );

    }
}

const mapStateToProps = state => {
    const {npcs, npc} = state;

    return {npcs, npc};
};

const mapDispatchToProps = dispatch => ({
    removeNpc: index => dispatch(removeNpc(index)),
    editNpc: npc => dispatch(editNpc(npc)),
    addContender: (contender) => dispatch(addContender(contender)),
    showMessage: message => dispatch(showMessage(message)),
    viewNpc: npc => dispatch(viewNpc(npc)),
});


export default connect(mapStateToProps, mapDispatchToProps)(NpcList);