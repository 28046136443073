import React from 'react';

class SensesForm extends React.Component {

    constructor(props) {
        super(props);

        const {
            blindsight = 0,
            darkvision = 0,
            truesight = 0,
            tremorsense = 0,
        } = props.senses;

        this.state = {
            senses: {
                blindsight,
                darkvision,
                truesight,
                tremorsense,
            }
        }
    }

    handleInputChange = (event) => {
        const {target: {name, value}} = event;
        const {senses} = this.state;

        senses[name] = value;

        this.setState({senses: {...senses}}, this.props.update(senses));
    };

    render() {
        const {blindsight, darkvision, truesight, tremorsense} = this.state.senses;

        return <div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="blindsight">Blindsight</label>
                    <input type="number" className="form-control" name="blindsight" id="blindsight" placeholder="Blindsight" value={blindsight} onChange={this.handleInputChange} />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="darkvision">Darkvision</label>
                    <input type="number" className="form-control" name="darkvision" id="darkvision" placeholder="Darkvision" value={darkvision} onChange={this.handleInputChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label htmlFor="truesight">Truesight</label>
                    <input type="number" className="form-control" name="truesight" id="truesight" placeholder="Truesight" value={truesight} onChange={this.handleInputChange} />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="tremorsense">Tremorsense</label>
                    <input type="number" className="form-control" name="tremorsense" id="tremorsense" placeholder="Tremorsense" value={tremorsense} onChange={this.handleInputChange} />
                </div>
            </div>
        </div>;
    }
}

export default SensesForm;