import React from 'react';

class SpellActivation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            type: props.allowEmpty ? '' : 'action',
            cost: props.allowEmpty ? '' : 1
        }
    }

    handleActivationChange = (event) => {
        const {target: {value, name}} = event;

        const change = {};
        change[name] = value;

        if (value === 'action' || value === 'bonus' || value === 'reaction') {
            change.cost = 1;
        }

        this.setState(change, () => this.props.onActivationChange(this.state));
    };

    render() {
        const {type = this.props.allowEmpty ? '' : 'action', cost = ''} = this.props;

        const singular = type === 'action' || type === 'bonus' || type === 'reaction';
        // const costValue = singular ? 1 : cost;

        return <div className="form-row">
            <div className="form-group col-6">
                <label htmlFor="activation_type">Activation Type</label>
                <select className="form-control" id="activation_type" name="type" onChange={this.handleActivationChange} value={type}>
                    {this.props.allowEmpty && <option value={''} />}
                    <option value="action">Action</option>
                    <option value="bonus">Bonus Action</option>
                    <option value="reaction">Reaction</option>
                    <option value="minute">Minute</option>
                </select>
            </div>
            <div className="form-group col-6">
                <label htmlFor="activation_cost">Cost</label>
                <input type="number" className="form-control" name="cost" id="activation_cost" aria-describedby="" placeholder="Cost" value={cost} onChange={this.handleActivationChange} disabled={singular} />
            </div>
        </div>;
    }
}

export default SpellActivation;