import React from 'react';
import {connect} from 'react-redux';
import {addContender, resetContenders} from '../../store/actions';
import Contender from '../../models/contender';

class ContenderForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            ac: 0,
            hp: 0,
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    };

    render() {
        return (
            <div>
                <form className="bg-dark text-white p-2 pb-3 rounded shadow">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" name="name" id="name" aria-describedby="" placeholder="Enter name" value={this.state.name} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="armorclass">AC</label>
                        <input type="number" className="form-control" name="ac" id="armorclass" aria-describedby="" placeholder="Enter AC" value={this.state.ac} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="hitpoints">HP</label>
                        <input type="number" className="form-control" name="hp" id="hitpoints" aria-describedby="" placeholder="Enter HP" value={this.state.hp} onChange={this.handleInputChange} />
                    </div>
                    <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => {
                        const {name, ac, hp} = this.state;
                        this.props.addContender(new Contender(name, ac, hp));
                    }}><i className="material-icons">face</i> Add</button>
                </form>
                <button className="mt-3 mb-3 btn btn-danger pl-5 position-relative" onClick={() => window.confirm('This will clear all contenders in current encounter. Are you sure?') && this.props.resetContenders()}>
                    <i className="material-icons">autorenew</i>Reset
                </button>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addContender: contender => dispatch(addContender(contender)),
    resetContenders: () => dispatch(resetContenders()),
});

export default connect(null, mapDispatchToProps)(ContenderForm);