import React from 'react';

class RemoteContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reloading: false,
        };
    }

    // The reloading thing is a hack te reload the iframe properly
    componentDidUpdate(prevProps, prevState) {
        const {url} = this.props;
        const {reloading} = this.state;

        const newState = {};

        if (url !== prevProps.url && !reloading) {
            newState.reloading = true;
        }

        if (reloading === true) {
            newState.reloading = false;
        }

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    render() {
        const {url} = this.props;
        const {reloading} = this.state;

        if (url && !reloading) {
            return <iframe is="x-frame-bypass" id="npc-content" className="w-100 mt-6" title={url} src={url} />
        }

        return null;
    }
}

export default RemoteContent;