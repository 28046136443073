import React from 'react';
import {connect} from 'react-redux';
import SkillList from './skillList';
import {showNpcSkillForm} from '../../store/actions';
import NpcSkillForm from './npcSkillForm';
import Spell from '../spell';
import NpcSpellForm from './npcSpellForm';

class NpcSkills extends React.Component {

    componentDidUpdate(prevProps) {
        const {editSkillIndex, skilltype} = this.props;

        if ((prevProps.editSkillIndex !== editSkillIndex) || (skilltype && prevProps.skilltype !== skilltype)) {
            const formElement = document.getElementById('skill-form');

            if (formElement) {
                formElement.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }
    }

    renderSkills = (title, skills, type) => {
        const {skilltype, npc, editSkillIndex} = this.props;

        return (
            <>
                {skilltype === type && <NpcSkillForm type={skilltype} npc={npc} editIndex={editSkillIndex} />}

                <div className="col-12 bg-secondary text-white p-2 pb-2 rounded shadow mt-4">
                    <h3 className="pl-2 pt-1 mb-1 d-flex flex-row justify-content-between">
                        <span>{title}</span>
                        <span>
                            <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => this.addSkill(type)}>
                                <i className="material-icons">photo_filter</i> Add
                            </button>
                        </span>
                    </h3>
                    <SkillList list={skills} type={type} removable={true} />
                </div>
            </>
        );
    };

    renderSpells = (spells, type) => {
        const {skilltype, npc, editSkillIndex} = this.props;

        return (
            <>
                {skilltype === 'spells' && <NpcSpellForm npc={npc} editIndex={editSkillIndex} />}

                <div className="col-12 bg-secondary text-white p-2 pb-2 rounded shadow mt-4">
                    <h3 className={`${spells.length > 0 ? 'mb-3': 'mb-1'} pl-2 pt-1 d-flex flex-row justify-content-between`}>
                        <span>Spells</span>
                        <span>
                            <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => this.addSpell()}>
                                <i className="material-icons">photo_filter</i> Add
                            </button>
                        </span>
                    </h3>
                    {spells.map((item, index) => {
                        return <Spell key={`spell-${item.name}`} spell={item} removable={true} index={index} />
                    })}
                </div>
            </>
        );
    };

    addSkill = (type) => {
        this.props.showNpcSkillForm(type);
    };

    addSpell = () => {
        this.props.showNpcSkillForm('spells');
    };

    render() {
        const {actions, special_abilities, legendary_actions, spells} = this.props;

        return (<>
            {this.renderSkills('Actions', actions ? [...actions] : [], 'actions')}
            {this.renderSkills('Special Abilities', special_abilities ? [...special_abilities] : [], 'special_abilities')}
            {this.renderSkills('Legendary Actions', legendary_actions ? [...legendary_actions] : [], 'legendary_actions')}
            {this.renderSpells(spells ? [...spells] : [], 'spells')}
        </>);
    }

}

const mapStateToProps = state => {
    const {skillType, npc, editSkillIndex} = state;
    const {actions, special_abilities, legendary_actions, spells} = npc;

    return {skilltype: skillType, actions, special_abilities, legendary_actions, spells, editSkillIndex};
};

const mapDispatchToProps = dispatch => ({
    showNpcSkillForm: type => dispatch(showNpcSkillForm(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NpcSkills);