import React from 'react';
import Icon from '@mdi/react';
import {
    mdiDiceD10Outline,
    mdiDiceD12Outline,
    mdiDiceD20Outline,
    mdiDiceD4Outline,
    mdiDiceD6Outline,
    mdiDiceD8Outline
} from '@mdi/js';

const DICE = [
    {
        key: 'd4',
        icon: mdiDiceD4Outline,
    },
    {
        key: 'd6',
        icon: mdiDiceD6Outline,
    },
    {
        key: 'd8',
        icon: mdiDiceD8Outline,
    },
    {
        key: 'd10',
        icon: mdiDiceD10Outline,
    },
    {
        key: 'd12',
        icon: mdiDiceD12Outline,
    },
    {
        key: 'd20',
        icon: mdiDiceD20Outline,
    },
];

class DiceSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hoverColumn: null,
        };
    }

    isSelected = (amount, dice) => {
        const {value} = this.props;

        return this.getRollString(amount, dice) === value;
    };

    getRollString = (amount, dice) => `${amount.toString()}${dice}`;
    handleUpdate = (amount, dice) => {
        this.props.onSelect(this.getRollString(amount, dice));
    };

    setHoverColumn = columnNumber => {
        this.setState({hoverColumn: columnNumber})
    }

    renderColumns = dice => {
        const columns = [];
        const {hoverColumn} = this.state;

        columns.push(<div className="col-2 pt-1" key={`${dice.key}-main`}><Icon path={dice.icon} size={1} /></div>);

        for (let i = 1; i <= 10; i++) {
            columns.push(<div
                key={`${dice.key}-amount-${i}`}
                className={`col-1 dice-option p-1 ${this.isSelected(i, dice.key) && 'selected'} ${hoverColumn === i ? 'hovering' : ''}`}
                onClick={() => this.handleUpdate(i, dice.key)}
                onMouseOver={() => this.setHoverColumn(i)}
                onMouseLeave={() => this.setHoverColumn(null)}
            >
                <span>{i}</span>
            </div>);
        }

        return columns;
    };

    renderRows = () => {
        const rows = [];

        DICE.map(dice => {
            rows.push(<div className="row dice-row" key={`dice-row-${dice.key}`}>
                {this.renderColumns(dice)}
            </div>);
        });

        return rows;
    };

    render() {
        return <div className="pr-2">
            {this.renderRows()}
        </div>;
    }
}

export default DiceSelector;