import React from 'react';
import {connect} from 'react-redux';
import {clearNpc, showMessage, switchNpcMode} from '../../store/actions';
import SkillList from './skillList';
import RemoteContent from '../remoteContent';
import Spell from '../spell';
import {getDistances} from '../helpers/form';
import {exportNpcToFoundry} from '../helpers/foundry';
import {copyToClipboard} from '../helpers/ui';
import {
    calcSpellDC,
    calculateAbilityModifier,
    calculateProficiencyFromChallengeRating,
    getSpellcastingModifer
} from '../helpers/npc';
import Icon from '@mdi/react';
import {mdiDiceD20Outline} from '@mdi/js';

class NpcView extends React.Component {

    renderSkills = (title, skills) => {
        return (
            <div className="col-12 bg-secondary text-white p-2 pb-3 rounded shadow mt-4">
                <h3 className="pl-2 pt-1">{title}</h3>
                <SkillList list={skills} />
            </div>
        );
    };

    renderSpells = (spells) => {
        return (
            <div className="col-12 bg-secondary text-white p-2 pb-3 rounded shadow mt-4">
                <h3 className="pl-2 pt-1 mb-3">Spells</h3>
                {spells.map(item => <Spell key={`spell-${item.name}`} spell={item} />)}
            </div>
        );
    };

    componentDidUpdate() {
        const {npc} = this.props;

        if (Object.keys(npc).length === 0) {
            this.props.switchNpcMode('edit');
        }
    }

    getModifierText = score => calculateAbilityModifier(score) >= 0 ? `+${calculateAbilityModifier(score)}` : calculateAbilityModifier(score).toString();

    foundryExport = () => {
        const {npc} = this.props;
        const json = exportNpcToFoundry(npc);
        copyToClipboard(JSON.stringify(json));
        this.props.showMessage('Foundry JSON copied to clipboard');
    };

    render() {
        const {npc} = this.props;

        return (
            <div>
                <div className="col-12 bg-dark text-white p-2 pb-3 rounded shadow">
                    <div className="row">
                        <div className="col-12 pl-4 mb-4 mt-4">
                            <h3 className="d-flex flex-row justify-content-between">
                                <span>{npc.name}</span>
                                <button className="btn table-btn text-danger" type="button" onClick={() => this.props.clearNpc()}>
                                    <i className="material-icons md-light">close</i>
                                </button>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 pl-4">Armor Class</div>
                        <div className="col-8">{npc.ac}</div>
                    </div>
                    <div className="row">
                        <div className="col-4 pl-4">Hitpoints</div>
                        <div className="col-8">{npc.hp}</div>
                    </div>
                    {npc.attackRoll && <div className="row">
                        <div className="col-4 pl-4">Attack Roll</div>
                        <div className="col-8">{npc.attackRoll}</div>
                    </div>}
                    {npc.damageRoll && <div className="row">
                        <div className="col-4 pl-4">Damage Roll</div>
                        <div className="col-8">{npc.damageRoll}</div>
                    </div>}

                    {npc.spellcasting_ability && <div className="row">
                        <div className="col-4 pl-4">Spell Save DC</div>
                        <div className="col-8" title="8 + Proficiency + Spellcasting Modifier">
                            {calcSpellDC(npc)} (8 + {calculateProficiencyFromChallengeRating(npc.challenge_rating)} + {getSpellcastingModifer(npc)})
                        </div>
                    </div>}

                    {npc.size && <div className="row">
                        <div className="col-4 pl-4">Size</div>
                        <div className="col-8">{npc.size}</div>
                    </div>}
                    {npc.type && <div className="row">
                        <div className="col-4 pl-4">Type</div>
                        <div className="col-8 text-capitalize">{npc.type}</div>
                    </div>}
                    {npc.alignment && <div className="row">
                        <div className="col-4 pl-4">Alignment</div>
                        <div className="col-8 text-capitalize">{npc.alignment}</div>
                    </div>}
                    {npc.speeds && <div className="row">
                        <div className="col-4 pl-4">Speed</div>
                        <div className="col-8">{getDistances(npc.speeds)}</div>
                    </div>}


                    {npc.strength && npc.dexterity && npc.constitution && npc.intelligence && npc.wisdom && npc.charisma && <div className="p-2">
                        <table className="mt-3 table table-bordered table-dark">
                            <thead>
                                <tr>
                                    <th>STR</th>
                                    <th>DEX</th>
                                    <th>CON</th>
                                    <th>INT</th>
                                    <th>WIS</th>
                                    <th>CHA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{npc.strength} ({this.getModifierText(npc.strength)})</td>
                                    <td>{npc.dexterity} ({this.getModifierText(npc.dexterity)})</td>
                                    <td>{npc.constitution} ({this.getModifierText(npc.constitution)})</td>
                                    <td>{npc.intelligence} ({this.getModifierText(npc.intelligence)})</td>
                                    <td>{npc.wisdom} ({this.getModifierText(npc.wisdom)})</td>
                                    <td>{npc.charisma} ({this.getModifierText(npc.charisma)})</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}


                    {npc.vulnerabilities.length > 0 && <div className="row">
                        <div className="col-4 pl-4">Damage Vulnerabilities</div>
                        <div className="col-8 text-capitalize">{npc.vulnerabilities.join(', ')}</div>
                    </div>}
                    {npc.resistances.length > 0 && <div className="row">
                        <div className="col-4 pl-4">Damage Resistances</div>
                        <div className="col-8 text-capitalize">{npc.resistances.join(', ')}</div>
                    </div>}
                    {npc.immunities.length > 0 && <div className="row">
                        <div className="col-4 pl-4">Damage Immunities</div>
                        <div className="col-8 text-capitalize">{npc.immunities.join(', ')}</div>
                    </div>}
                    {npc.conditionImmunities.length > 0 && <div className="row">
                        <div className="col-4 pl-4">Condition Immunities</div>
                        <div className="col-8 text-capitalize">{npc.conditionImmunities.join(', ')}</div>
                    </div>}
                    {npc.senses.length > 0 && <div className="row">
                        <div className="col-4 pl-4">Senses</div>
                        <div className="col-8">{getDistances(npc.senses)}</div>
                    </div>}
                    {npc.languages.length > 0 && <div className="row">
                        <div className="col-4 pl-4">Languages</div>
                        <div className="col-8 text-capitalize">{npc.languages.join(', ')}</div>
                    </div>}
                    {npc.challenge_rating && <div className="row">
                        <div className="col-4 pl-4">Challenge Rating</div>
                        <div className="col-8">{npc.challenge_rating}</div>
                    </div>}

                    {npc.content && <div className="row">
                        <div className="col-12 pl-4 pr-4">
                            <div className="bg-secondary mt-4 mb-4 p-2 rounded white-space-pre">
                                {npc.content}
                            </div>
                        </div>
                    </div>}

                    {npc.url && <div className="row mb-6">
                        <div className="col-4 pl-4">Content Url</div>
                        <div className="col-8 pr-4">
                            <a href={npc.url} target="__blank" rel="noopener noreferrer">
                                Content
                            </a>
                        </div>
                    </div>}

                    <button type="button" className="btn btn-info mt-5 pl-5 ml-2 position-relative" onClick={this.foundryExport}>
                        <i className="material-icons"><Icon path={mdiDiceD20Outline} size={1} /></i>Foundry
                    </button>
                </div>

                {npc.actions && npc.actions.length > 0 && this.renderSkills('Actions', npc.actions)}
                {npc.special_abilities && npc.special_abilities.length > 0 && this.renderSkills('Special Abilities', npc.special_abilities)}
                {npc.legendary_actions && npc.legendary_actions.length > 0 && this.renderSkills('Legendary Actions', npc.legendary_actions)}
                {npc.spells && npc.spells.length > 0 && this.renderSpells(npc.spells)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {npc} = state;

    return {npc};
};

const mapDispatchToProps = dispatch => ({
    clearNpc: () => dispatch(clearNpc()),
    switchNpcMode: (mode) => dispatch(switchNpcMode(mode)),
    showMessage: message => dispatch(showMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NpcView);