import {NPC_SKILLS} from '../npc/npcConstants';

export function inList(array, value) {
    if (!array) {
        return false;
    }

    return array.indexOf(value) >= 0;
}

export function addValueToList(event, list = []) {
    const {target} = event;
    const {value} = target;

    const index = list.indexOf(value);

    if (target.checked && index < 0) {
        list.push(value);
    } else if (!target.checked && index >= 0) {
        list.splice(index, 1);
    }

    return list;
}

export function getDistances(speeds) {
    if (!speeds) {
        return '';
    }

    let speedStrings = [];

    Object.keys(speeds).forEach(name => {
        if (speeds[name] > 0) {
            if (name === 'walking') {
                speedStrings.push(`${speeds[name]} ft`);
            } else {
                speedStrings.push(`${name} ${speeds[name]} ft`);
            }
        }
    });

    return speedStrings.join(', ');
}

export function getProficiencies(skills) {
    if (!skills) {
        return '';
    }

    const activeSkills = NPC_SKILLS.filter(skill => skills.includes(skill.key)).map(item => item.name);

    return activeSkills.join(', ');
}

export function getColumns(dataArray, amount) {
    const columns = [];
    const typesInColumn = Math.ceil(dataArray.length / amount);
    let start = 0;

    for (let colIndex = 0; colIndex < amount; colIndex++) {
        let column = [];
        column = dataArray.slice(start, start + typesInColumn);
        columns.push(column);
        start += typesInColumn;
    }

    return columns;
}