import React from 'react';
import {connect} from 'react-redux';
import {editNpcSpell} from '../../store/actions';

const spells = require('../../dataset/spells');

class SpellSearch extends React.Component {

    constructor(props) {
        super(props);

        this.initState = {
            query: '',
            results: [],
        };

        this.state = this.initState;
    }

    search = (event) => {
        const {value} = event.target;

        const results = spells.filter((item) => {
            return value !== '' && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        });

        this.setState({
            query: value,
            results,
        });
    };

    selectSpell = (item) => {
        this.props.editNpcSpell(item);

        this.setState({...this.initState});
    };

    render() {
        const {query, results} = this.state;

        return (
            <div className="form-row position-relative">
                <div className="form-group col-12">
                    <label htmlFor="url">Spell</label>
                    <input type="text" className="form-control" name="spell-search" id="spell-search" aria-describedby="" placeholder="Search..." value={query} onChange={this.search} />
                </div>
                <div id="spell-results" className={`col-12 list-group position-absolute overflow-auto ${results.length > 0 ? 'visible' : 'invisible'}`}>
                    {results.map((item, index) => {
                        return <a href="#" key={`spell-${index}`} className="list-group-item list-group-item-primary list-group-item-action rounded-bottom shadow-sm" onClick={(event) => {
                            event.preventDefault();
                            this.selectSpell(item);
                        }}>
                            {item.name}
                        </a>
                    })}
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    editNpcSpell: spell => dispatch(editNpcSpell(spell)),
});

export default connect(null, mapDispatchToProps)(SpellSearch);