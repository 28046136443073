import {createRandomString} from '../store/helpers';

export default class Model {
    randomNumber(min, max) {
        return parseInt((Math.random() * max)) + min;
    }

    createRandomString(length) {    
        return createRandomString(length);
    }  
}