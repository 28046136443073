import React from 'react';
import Player from '../../models/player';
import {addPlayer, updatePlayer} from '../../store/actions';
import {connect} from 'react-redux';

class PlayerForm extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            player: {
                id: '',
                name: '',
                race: '',
                ac: 0,
                hp: 0,
                level: '',
                background: '',
                perception: 10,
                investigation: 10,
                insight: 10,
            }
        };
        this.state = this.getInitState();
    }

    getInitState = () => {
        return {...this.initialState};
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const {player} = this.state;
        player[name] = value;

        this.setState({player});
    };

    componentDidUpdate = (prevProps) => {
        const {player} = this.props;

        if (player.id && prevProps.player.id !== player.id) {
            this.setState({player: player.toObject()});
        }
    };

    reset = () => {
        this.setState(this.getInitState());
    };

    render() {
        return (
            <div className="col-12 bg-dark text-white p-2 pb-3 rounded shadow mt-3">
                <form className="">
                    <div className="form-row">
                        <div className="form-group col-3">
                            <label htmlFor="name">Name</label>
                            <input type="text" className="form-control" name="name" id="name" aria-describedby="" placeholder="Enter name" value={this.state.player.name} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-3">
                            <label htmlFor="armorclass">AC</label>
                            <input type="number" className="form-control" name="ac" id="armorclass" aria-describedby="" placeholder="Enter AC" value={this.state.player.ac} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-3">
                            <label htmlFor="hitpoints">HP</label>
                            <input type="number" className="form-control" name="hp" id="hitpoints" aria-describedby="" placeholder="Enter HP" value={this.state.player.hp} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-3">
                            <label htmlFor="level">Level</label>
                            <input type="number" className="form-control" name="level" id="level" aria-describedby="" placeholder="Enter Level" value={this.state.player.level} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-6">
                            <label htmlFor="race">Race</label>
                            <input type="text" className="form-control" name="race" id="race" aria-describedby="" placeholder="Enter Race" value={this.state.player.race} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="background">Background</label>
                            <input type="text" className="form-control" name="background" id="background" aria-describedby="" placeholder="Enter Background" value={this.state.player.background} onChange={this.handleInputChange} />
                        </div>                    
                    </div>
                    <div className="form-row">
                        <div className="form-group col-4">
                            <label htmlFor="perception">Perception</label>
                            <input type="number" className="form-control" name="perception" id="perception" aria-describedby="" placeholder="Enter Perception" value={this.state.player.perception} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="investigation">Investigation</label>
                            <input type="number" className="form-control" name="investigation" id="investigation" aria-describedby="" placeholder="Enter Investigation" value={this.state.player.investigation} onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="insight">Insight</label>
                            <input type="number" className="form-control" name="insight" id="insight" aria-describedby="" placeholder="Enter Insight" value={this.state.player.insight} onChange={this.handleInputChange} />
                        </div>
                    </div>
                    {!this.state.player.id && <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => {
                        const {name, ac, hp, race, level, background, perception, investigation, insight} = this.state.player;
                        const player = new Player(name, ac, hp, race, level, background, perception, investigation, insight);

                        this.props.addPlayer(player);
                        this.reset();
                        
                    }}><i className="material-icons">face</i> Add</button>}
                    {this.state.player.id && <div>
                        <button type="button" className="btn btn-info pl-5 position-relative" onClick={() => {
                            const {player} = this.props;                            
                            player.update(this.state.player);
                            this.props.updatePlayer(player);
                        }}>
                            <i className="material-icons">face</i> Update
                        </button>
                        <button className="btn btn-danger pl-5 ml-2 position-relative" onClick={this.reset}>
                            <i className="material-icons">autorenew</i>Clear
                        </button>
                    </div>}
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {player} = state;

    return {player};
};

const mapDispatchToProps = dispatch => ({
    addPlayer: (player) => dispatch(addPlayer(player)),
    updatePlayer: (player) => dispatch(updatePlayer(player)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerForm);