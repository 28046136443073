import React from 'react';
import {editNpc} from '../../store/actions';
import {connect} from 'react-redux';
import Npc from '../../models/npc';

const monsters = require('../../dataset/monsters');

/**
 * Monster dataset: // https://dl.dropboxusercontent.com/s/iwz112i0bxp2n4a/5e-SRD-Monsters.json
 *
 * Posted here: https://www.reddit.com/r/dndnext/comments/43a09o/srd_monsters_in_json_format/
 */
class MonsterSearch extends React.Component {

    constructor(props) {
        super(props);

        this.initState = {
            query: '',
            results: [],
        };

        this.state = this.initState;

        this.abilities = {
            special_abilities: {},
            actions: {},
            legendary_actions: {},
        };
    }

    search = (event) => {
        const {value} = event.target;

        const results = monsters.filter((item) => {
            return value !== '' && !item.license && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        });

        this.setState({
            query: value,
            results,
        });

        for (const monster of monsters) {
            if (monster.special_abilities) {
                for (const skill of monster.special_abilities) {
                    this.abilities.special_abilities[skill.name] = skill
                }
            }

            if (monster.actions) {
                for (const skill of monster.actions) {
                    this.abilities.actions[skill.name] = skill
                }
            }

            if (monster.legendary_actions) {
                for (const skill of monster.legendary_actions) {
                    this.abilities.legendary_actions[skill.name] = skill
                }
            }
        }

    };

    selectMonster = (item) => {
        this.props.onSelect();
        this.props.editNpc(Npc.fromDataItem(item));

        this.setState({...this.initState});
    };

    render() {
        const {query, results} = this.state;

        return (
            <div className="form-row position-relative">
                <div className="form-group col-12">
                    <label htmlFor="url">NPC Base</label>
                    <input type="text" className="form-control" name="monster-search" id="monster-search" aria-describedby="" placeholder="Search..." value={query} onChange={this.search} />
                </div>
                <div id="monster-results" className={`col-12 list-group position-absolute overflow-auto ${results.length > 0 ? 'visible' : 'invisible'}`}>
                    {results.map((item, index) => {
                        return <a href="#" key={`monster-${index}`} className="list-group-item list-group-item-primary list-group-item-action rounded-bottom shadow-sm" onClick={() => {this.selectMonster(item)}}>
                            {item.name}
                        </a>
                    })}
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => ({
    editNpc: npc => dispatch(editNpc(npc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonsterSearch);