import {NPC_SKILLS} from '../npc/npcConstants';
import {createRandomString} from '../../store/helpers';
import {calcSpellDC} from './npc';

export function exportNpcToFoundry(npc) {
    return {
        _id: npc.id,
        name: npc.name,
        type: 'npc',
        data: {
            abilities: getAbilities(npc),
            attributes: getAttributes(npc),
            details: getDetails(npc),
            traits: getTraits(npc),
            skills: getSkills(npc),
            spells: getSpellSlots(),
        },
        items: getItems(npc),
        token: getToken(npc),
    };
}

function getAbilities(npc) {
    const {
        strength,
        dexterity,
        constitution,
        intelligence,
        wisdom,
        charisma,
        strProficient,
        dexProficient,
        conProficient,
        intProficient,
        wisProficient,
        chaProficient,
    } = npc;

    return {
        str: {
            value: strength,
            proficient: strProficient,
        },
        dex: {
            value: dexterity,
            proficient: dexProficient,
        },
        con: {
            value: constitution,
            proficient: conProficient,
        },
        int: {
            value: intelligence,
            proficient: intProficient,
        },
        wis: {
            value: wisdom,
            proficient: wisProficient,
        },
        cha: {
            value: charisma,
            proficient: chaProficient,
        }
    };
}

function getAttributes(npc) {
    const {
        ac,
        hp,
        speeds: {walking, climbing, burrowing, flying, swimming},
        spellcasting_ability,
    } = npc;
    
    return {
        ac: {
            value: ac
        },
        hp: {
            value: hp,
            min: 0,
            max: hp,
        },
        movement: {
            burrow: burrowing,
            climb: climbing,
            fly: flying,
            swim: swimming,
            walk: walking,
            units: 'ft',
        },
        spellcasting: spellcasting_ability ? spellcasting_ability.substring(0,3) : null,
        spelldc: calcSpellDC(npc)
    };
}

function getDetails(npc) {
    const {alignment, challenge_rating, type, spellcasting_ability} = npc;

    return {
        alignment: alignment,
        type: type,
        cr: challenge_rating,
        spellLevel: spellcasting_ability ? 20 : 0,
    };
}

function getTraits(npc) {
    const {
        size,
        immunities,
        vulnerabilities,
        resistances,
        conditionImmunities,
        senses,
        languages,
    } = npc;

    return {
        size: getSize(size),
        di: {
            value: immunities,
        },
        dr: {
            value: resistances,
        },
        dv: {
            value: vulnerabilities,
        },
        ci: {
            value: conditionImmunities,
        },
        senses: getSenses(senses),
        languages: {
            value: languages
        },
    };
}

function getSize(size) {
    const sizes = {
        tiny: 'tiny',
        small: 'sm',
        medium: 'med',
        large: 'lg',
        huge: 'huge',
        gargantuan: 'grg'
    };

    if (size) {
        return sizes[size];
    }

    return sizes.medium;
}

function getSenses(senses) {
    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    return Object.keys(senses).filter(sense => senses[sense] > 0).map(sense => sense.capitalize()).join(', ');
}

function getSkills(npc) {
    const {skills = []} = npc;

    const skillObj = {};

    NPC_SKILLS.forEach(skill => {
        skillObj[skill.key] = {
            value: skills.includes(skill.key) ? 1 : 0,
            ability: skill.ability,
        };
    });

    return skillObj;
}

function getSpellSlots() {
    return {
        "spell1": {
            "value": 4,
            "override": null,
            "max": 4
        },
        "spell2": {
            "value": 3,
            "override": null,
            "max": 3
        },
        "spell3": {
            "value": 3,
            "override": null,
            "max": 3
        },
        "spell4": {
            "value": 3,
            "override": null,
            "max": 3
        },
        "spell5": {
            "value": 3,
            "override": null,
            "max": 3
        },
        "spell6": {
            "value": 1,
            "override": null,
            "max": 1
        },
        "spell7": {
            "value": 1,
            "override": null,
            "max": 1
        },
        "spell8": {
            "value": 1,
            "override": null,
            "max": 1
        },
        "spell9": {
            "value": 1,
            "override": null,
            "max": 1
        },
    };
}

function getTokenSize(size) {
    const sizes = {
        tiny: 1,
        small: 1,
        medium: 1,
        large: 2,
        huge: 2,
        gargantuan: 3,
    };

    if (size) {
        return sizes[size];
    }

    return 4;
}

function getTokenScale(size) {
    const sizes = {
        tiny: 0.5,
        small: 0.8,
        medium: 1,
        large: 1,
        huge: 1.5,
        gargantuan: 1,
    };

    if (size) {
        return sizes[size];
    }

    return 1;
}

function getDimSight(senses) {
    const {darksight = 0, blindsight = 0} = senses;

    return Math.max(darksight, blindsight);
}

function getToken(npc) {
    const {id, name, size, senses = {}} = npc;

    return {
        name: name,
        img: 'icons/svg/mystery-man.svg',
        width: getTokenSize(size),
        height: getTokenSize(size),
        scale: getTokenScale(size),
        lockRotation: true,
        vision: true,
        dimSight: getDimSight(senses),
        brightSight: 300,
        sightAngle: 360,
        lightAngle: 360,
        actorId: id,
        displayBars: 40,
        bar1: {
            attribute: 'attributes.hp'
        },
        bar2: {
            attribute: 'attributes.ac.value'
        },
    };
}

function getItems(npc) {
    const {actions, spells, special_abilities, legendary_actions, spellcasting_ability} = npc;

    return [
        ...getActionItems(actions),
        ...getSpellItems(spells, spellcasting_ability),
        ...getSpecialAbilitiesItems(special_abilities),
        ...getLegendaryActionItems(legendary_actions),
    ];
}

function getActionItems(actions = []) {
    return actions.map(action => getActionData(action));
}

function getSpellItems(spells = [], spellcastingAbility) {
    return spells.map(spell => {
        const data = getActionData(spell, 'spell');

        // Prevent spellcasting errors in console
        data.data.damage = spell.damage && spell.damage.parts ? spell.damage : {parts: []};
        data.data.school = "evo";
        data.data.ability = spellcastingAbility.substr(0, 3);

        return data;
    });
}

function getSpecialAbilitiesItems(actions = []) {
    return actions.map(action => getActionData(action, 'feat'));
}

function getLegendaryActionItems(actions = []) {
    return actions.map(action => getActionData(action, 'feat'));
}

function getActionData(action, type = 'weapon') {
    const {
        name,
        attack_bonus,
        desc,
        description,
        activation,
        duration,
        target,
        range,
        level,
        ritual,
        concentration,
        damage_parts
    } = action;

    return {
        _id: createRandomString(),
        name: name,
        type: type,
        data: {
            description: {
                value: `<p>${desc || description}</p>`,
            },
            source: "rollinitiative.weak.dog",
            activation: {
                type: activation.type,
                cost: activation.cost,
                condition: '',
            },
            duration: {
                value: duration.value,
                units: duration.units,
            },
            target: {
                value: target.value,
                width: target.width,
                units: target.units,
                type: target.type
            },
            range: {
                value: range.value,
                long: 0,
                units: range.units
            },
            actionType: findActionType(action, type),
            attackBonus: attack_bonus,
            critical: null,
            damage: {
                parts: damage_parts,
                versatile: '',
                value: ''
            },
            level: level || 1,
            components: {
                ritual: ritual,
                concentration: concentration
            },
            preparation: {
                mode: 'prepared',
                prepared: true
            },
            uses: {}
        }
    };
}

function findActionType(action, type) {
    if (type === 'spell') {
        const {damage: {parts}, range} = action;

        if (parts && parts.length > 0) {
            return range.value > 10 ? 'rsak' : 'msak';
        }
    } else {
        const {damage_parts, range} = action;

        if (damage_parts && damage_parts.length > 0) {
            if (range.value > 10) {
                return 'rwak';
            }
            return 'mwak';
        }
    }

    return 'util';
}
