export function findRollsFromDamage(damage) {
    const expr = /([0-9]{1,2}d[0-9]{1,2}).*/
    const match = damage.match(expr);

    if (match) {
        return match[1].toLowerCase();
    }

    return '';
}

export function findDamageBonusFromDamage(damage) {
    const expr = /[0-9]{1,2}d[0-9]{1,2} \+ ([0-9]{1,2})/
    const match = damage.match(expr);

    if (match) {
        return match[1];
    }

    return 0;
}