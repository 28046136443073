import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';

class Popover extends React.Component {

    captureClick = event => {
        if (event.target.classList.contains('overlay')) {
            this.props.close && this.props.close();
        }
    };

    render() {
        const overlay = document.getElementById('overlay');

        return ReactDOM.createPortal(
            <div className="overlay d-flex justify-content-center" onClick={this.captureClick}>
                <div className="overlay-content bg-dark text-white p-4 pb-2 pt-4 rounded shadow">
                    <h4 className="mb-4">{this.props.title}</h4>
                    {this.props.children}
                </div>
            </div>,
            overlay
        );
    }
}

const mapStateToProps = state => {
    const {popover} = state;

    return {popover};
};

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Popover);