import React from 'react';
import {navigateToNpc, removeContender, updateContender} from '../../store/actions';
import {connect} from 'react-redux';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';

class ContenderList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contenders: [],
        };
    }

    findNpc = (id) => {
        const {npcs} = this.props;

        for (const npc of npcs) {
            if (npc.id === id) {
                return npc;
            }
        }

        return null;
    };

    changeColor = (index, colorObj) => {
        const {color} = colorObj;

        this.props.updateValue(index, 'color', color);
    };

    renderName = (contender) => {
        const {name, type, npcId} = contender;

        if (type === 'npc' && npcId) {
            return <button className="bg-transparent" onClick={(event) => {
                event.preventDefault();
                const npc = this.findNpc(npcId);

                if (npc) {
                    this.props.navigateToNpc(npc);
                }
            }}>{name}</button>
        }

        return <span>{name}</span>;
    };

    render() {
        const {contenders} = this.props;

        return (
            <div className="border border-secondary p-2 rounded bg-white shadow">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className="th-initiative border-top-0 position-relative">Initiative <i className="material-icons d-none d-md-block">timelapse</i></th>
                            <th className="border-top-0">Name</th>
                            <th className="th-ac border-top-0 position-relative">AC <i className="material-icons d-none d-md-block">security</i></th>
                            <th className="th-hp border-top-0 position-relative">HP <i className="material-icons d-none d-md-block">favorite</i></th>
                            <th className="th-hpbtn border-top-0" />
                            <th className="th-rm border-top-0" />
                        </tr>                    
                    </thead>
                    <tbody>
                        {contenders.map((contender, index) => {
                            return (
                                <tr key={`${contender}-${contender.id}`}>
                                    <td>
                                        <input className="form-control form-control-sm" type="number" value={contender.initiative} onChange={(event) => this.props.updateValue(index, 'initiative', event.target.value)} />
                                    </td>
                                    <td className="d-flex justify-content-between align-items-center">
                                        {this.renderName(contender)}
                                        <ColorPicker color={contender.color} onChange={(color) => this.changeColor(index, color)} enableAlpha={false} />
                                    </td>
                                    <td>
                                        <input className="form-control form-control-sm" type="number" value={contender.ac} onChange={(event) => this.props.updateValue(index, 'ac', event.target.value)} />
                                    </td>
                                    <td>
                                        <input className="form-control form-control-sm" type="number" value={contender.hp} onChange={(event) => this.props.updateValue(index, 'hp', event.target.value)} />
                                    </td>
                                    <td className="td-hp-change">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-danger btn-sm" type="button" onClick={() => this.props.updateValue(index, 'hp', contender.hp - contender.changeValue)}>-</button>
                                            </div>
                                            <input className="form-control form-control-sm" type="number" onChange={(event) => this.props.updateValue(index, 'changeValue', event.target.value)} value={contender.changeValue} />
                                            <div className="input-group-append d-none d-md-block">
                                                <button className="btn btn-success btn-sm" type="button" onClick={() => this.props.updateValue(index, 'hp', contender.hp + Number(contender.changeValue))}>+</button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <button className="btn table-btn text-danger" type="button" onClick={() => window.confirm(`Remove ${contender.name} from encounter?`) && this.props.removeContender(index)}>
                                            <i className="material-icons md-light">close</i>
                                        </button>
                                    </td>
                                </tr>
                            );                
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {contenders, npcs} = state;

    return {contenders, npcs};
};

const mapDispatchToProps = dispatch => ({
    removeContender: index => dispatch(removeContender(index)),
    updateValue: (index, name, value) => dispatch(updateContender(index, name, value)),
    navigateToNpc: npc => dispatch(navigateToNpc(npc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContenderList);