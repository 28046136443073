import React from 'react';
import InitiativeList from './components/initiativeList';
import './App.css';

function App() {
    return (
        <div className="container position-relative">
            <InitiativeList />
        </div>
    );
}

export default App;
