import React from 'react';
import {connect} from 'react-redux';
import {addSkillToCurrentNpc, showMessage, updateNpcSpell} from '../../store/actions';
import SpellSearch from './spellSearch';
import SkillRange from './skillRange';
import SpellActivation from './spellActivation';
import SkillDuration from './skillDuration';
import SkillDamageRolls from './skillDamageRolls';
import {calculateAbilityModifier, calculateProficiencyFromChallengeRating} from '../helpers/npc';

class NpcSpellForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            spell: {...this.initSpell()},
        };
    }

    componentDidMount() {
        this.populateForm();
    }

    componentDidUpdate(prevProps, prevState) {
        const {spell, editIndex} = this.props;

        if (spell !== prevProps.spell) {
            if (Object.keys(spell).length === 0) {
                this.setState({spell: {...this.initSpell()}});
            } else {
                this.setState({spell: {...this.initSpell(), ...spell}});
            }
        }

        if (editIndex !== null && prevProps.editIndex !== editIndex) {
            this.populateForm();
        } else if (editIndex === null && prevProps.editIndex !== editIndex) {

        }
    }

    initSpell = () => {
        return {
            type: 'spells',
            name: '',
            ritual: '',
            casting_time: '',
            description: '',
            level: '',
            range: {},
            target: {},
            damage_parts: [],
            activation: {},
            duration: {},
            damage: {},
            attack_bonus: 0,
        };
    }

    populateForm = () => {
        const {editIndex, npc} = this.props;
        const skill = npc['spells'][editIndex];

        if (skill) {
            const {name, description, ritual, concentration, casting_time, duration, level, range, target, activation, damage = [], attack_bonus = 0} = skill;

            this.setState({spell: {type: 'spells', name, description, damage, ritual, concentration, casting_time, duration, level, range, target, activation, attack_bonus}});
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const {spell} = this.state;

        spell[name] = target.value;
        this.setState({spell: {...spell}});
    };

    addSpellToNpc = (npc, spell) => {
        spell.type = 'spells';

        this.props.addSpellToCurrentNpc(npc, spell);
        this.props.showMessage(`${spell.name} added to NPC`);
    };

    updateSpellOfNpc = (npc, spell, index) => {
        this.props.updateNpcSpell(npc, spell, index);
        this.props.showMessage(`${spell.name} updated`);
    };

    updateRitual = (event) => {
        const {spell} = this.state;
        spell.ritual = event.target.checked;
        this.setState({spell: {...spell}});
    };

    updateConcentration = (event) => {
        const {spell} = this.state;
        spell.concentration = event.target.checked;
        this.setState({spell: {...spell}});
    };

    updateActivation = activation => {
        const {spell} = this.state;
        spell.activation = activation;
        this.setState({spell: {...spell}});
    };

    updateRange = range => {
        const {spell} = this.state;
        spell.range = range;
        this.setState({spell: {...spell}});
    }

    updateTarget = target => {
        const {spell} = this.state;
        spell.target = target;
        this.setState({spell: {...spell}});
    }

    onDurationChange = duration => {
        const {spell} = this.state;
        spell.duration = duration;
        this.setState({spell: {...spell}});
    };

    updateDamageParts = parts => {
        const {spell} = this.state;

        const damage_parts = parts.map(part => [
            `${part.rolls} + ${part.bonus}`,
            part.type,
        ]);

        spell.damage = {};
        spell.damage.parts = damage_parts;
        this.setState({spell: {...spell}});
    };

    calculateDefaultSpellAttack = () => {
        const {npc: {challenge_rating, intelligence}} = this.props;

        return calculateProficiencyFromChallengeRating(challenge_rating) + calculateAbilityModifier(intelligence);
    };

    render() {
        const {npc, editIndex} = this.props;
        const {spell} = this.state;
        const {activation, duration, damage: {parts = []}} = spell;

        return (
            <div id="skill-form" className="col-12 bg-dark text-white p-2 pb-3 rounded shadow mt-4">
                <SpellSearch />
                <div className="form-row">
                    <div className="form-group col-9">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" name="name" id="name" aria-describedby="" placeholder="Name" value={spell ? spell.name : ''} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group col-3">
                        <label htmlFor="attackBonus">Attack Bonus</label>
                        <input type="text" className="form-control" name="attack_bonus" id="attackBonus" aria-describedby="" placeholder="Attack Bonus" value={spell.attack_bonus} onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="form-row align-items-center">
                    <div className="form-group col-6">
                        <label htmlFor="level">Level</label>
                        <input type="text" className="form-control" name="level" id="level" aria-describedby="" placeholder="Level" value={spell ? spell.level : ''} onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group col-3">
                        <div className="form-check mt-4">
                            <input className="form-check-input" type="checkbox" checked={spell && spell.concentration === true} id="concentration" name="concentration" onChange={this.updateConcentration} />
                            <label className="form-check-label" htmlFor="concentration">
                                Concentration
                            </label>
                        </div>
                    </div>
                    <div className="form-group col-3">
                        <div className="form-check mt-4">
                            <input className="form-check-input" type="checkbox" checked={spell && spell.ritual} id="ritual" name="ritual" onChange={this.updateRitual} />
                            <label className="form-check-label" htmlFor="ritual">
                                Ritual
                            </label>
                        </div>
                    </div>
                </div>

                <SpellActivation onActivationChange={this.updateActivation} cost={activation.cost} type={activation.type} />
                <SkillDuration onDurationChange={this.onDurationChange} units={duration.units} value={duration.value} />
                <SkillRange range={spell.range} target={spell.target} onChangeRange={this.updateRange} onChangeTarget={this.updateTarget} />

                <div className="form-row">
                    <div className="form-group col-12">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control" name="description" id="description" rows="5" value={spell && spell.description} onChange={this.handleInputChange} />
                    </div>
                </div>

                <SkillDamageRolls parts={parts} onChange={this.updateDamageParts} />

                <span>
                    {editIndex === null && <button type="button" className="btn btn-success pl-5 position-relative" onClick={() => this.addSpellToNpc(npc, spell)}>
                        <i className="material-icons">add</i> Add spell
                    </button>}

                    {editIndex !== null && <button type="button" className="btn btn-info pl-5 position-relative" onClick={() => this.updateSpellOfNpc(npc, spell, editIndex)}>
                        <i className="material-icons">stars</i> Update Spell
                    </button>}
                </span>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addSpellToCurrentNpc: (npc, spell) => dispatch(addSkillToCurrentNpc(npc, spell)),
    showMessage: message => dispatch(showMessage(message)),
    updateNpcSpell: (npc, spell, index) => dispatch(updateNpcSpell(npc, spell, index)),
});

const mapStateToProps = state => {
    const {spell} = state;

    return {spell};
};

export default connect(mapStateToProps, mapDispatchToProps)(NpcSpellForm);